import { AlertType, AlertTypes } from './types';

export const getBgClass = (type?: AlertType) => {
  switch (type) {
    case AlertTypes.info:
      return 'bg-blue-100';
    case AlertTypes.success:
      return 'bg-green-100';
    case AlertTypes.warning:
      return 'bg-yellow-100';
    case AlertTypes.danger:
      return 'bg-red-100';
    default:
      return 'bg-gray-200';
  }
};
