import { PropsWithChildren } from 'react';

interface CardProps {}

const Card = ({ children }: PropsWithChildren<CardProps>) => {
  return (
    <div className='h-full px-4 py-6 border border-gray-200'>{children}</div>
  );
};

export default Card;
