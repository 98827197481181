import { z } from 'zod';
import { translateLanguage } from './translateLanguage.utils';

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
];

// TODO: replace with actual max + update help/error messages
const MAX_FILE_SIZE = 5000000;

export const generateFileUploadSchema = (locale: string = 'fr') =>
  z.object({
    fileType: z.enum(['jpg', 'png', 'pdf'], {
      message: translateLanguage('common.forms.required', locale),
    }),
    file: z
      .instanceof(FileList)
      .refine(
        (files) => files?.length === 1,
        translateLanguage('common.forms.required', locale)
      )
      .refine(
        (files) => files?.[0]?.size <= MAX_FILE_SIZE,
        translateLanguage('common.forms.invalid.maxSize', locale)
      )
      .refine(
        (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
        translateLanguage('common.forms.invalid.fileType', locale)
      ),
  });

export const FileUploadSchemaVal = generateFileUploadSchema();
export type FileUploadSchema = z.infer<typeof FileUploadSchemaVal>;
