import { goBack } from '../utils/navigation.utils';
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';
import Button from '../forms/Button';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const { t } = useTranslation();

  return (
    <Button
      label={t('common.back')}
      onClick={goBack}
      className='mt-2'
      compact
      icon={<ArrowLongLeftIcon width={20} height={20} />}
    />
  );
};

export default BackButton;
