import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Portal from './pages/Portal';
import ParentAuth from './pages/ParentAuth';
import PersonalInfo from './pages/PersonalInfo';
import RequestAccess from './pages/RequestAccess';
import PriorAuth from './pages/PriorAuth';
import ChangeRequest from './pages/ChangeRequest';
import {
  mockDraftRequestData,
  mockRequestData,
} from './mockData/mockRequestData';
import MessageDetails from './pages/MessageDetails';
import NewMessage from './pages/NewMessage';
import { ApiProvider, useApi } from './api/APIContext';
import SurrogateNotice from './pages/SurrogateNotice';
import Spinner from './components/Spinner';
import { navigateTo } from './utils/navigation.utils';
import WaitForDemande from './pages/WaitForDemande';

function Router({ path }: { path: string }) {
  const { protectRoute, isAuthenticated } = useApi();
  const [isAuthenticating, setIsAuthenticating] = useState(!isAuthenticated());

  useEffect(() => {
    if (isAuthenticated()) {
      return setIsAuthenticating(false);
    }
    protectRoute().then((jwt) => {
      if (jwt) {
        setIsAuthenticating(false);
      }
    });
  }, [isAuthenticated, protectRoute]);

  if (isAuthenticating)
    return (
      <div>
        Chargement... <Spinner />
      </div>
    );

  switch (path) {
    case '/':
      return <Home />;
    case '/wait':
      return <WaitForDemande />;
    case '/dashboard':
      return <Dashboard />;
    case '/contact':
      return <Contact />;
    case '/priorAuth':
      return <PriorAuth />;
    case '/parentAuth':
      return <ParentAuth />;
    case '/personalInfo':
      return <PersonalInfo />;
    case '/requestAccess':
      return <RequestAccess />;
    case '/portal':
      return <Portal />;
    case '/changeRequest':
      return <ChangeRequest />;
    case '/message':
      return <MessageDetails />;
    case '/newMessage':
      return <NewMessage />;
    case '/surrogateNotice':
      return <SurrogateNotice requestData={mockRequestData} />;
    default:
      navigateTo('/');
      return <></>;
  }
}

function App({
  language,
  path,
  appurl,
  azfunctionsurl,
  oauthurl,
  oauthclientid,
}: {
  language?: string;
  path?: string;
  appurl?: string;
  azfunctionsurl?: string;
  oauthurl?: string;
  oauthclientid?: string;
}) {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language).then();
  }, [i18n, language]);

  return (
    <ApiProvider
      {...{
        appUrl: appurl,
        azFunctionsUrl: azfunctionsurl,
        oauthClientId: oauthclientid,
        oAuthUrl: oauthurl,
      }}
    >
      <Router path={path || '/'} />
    </ApiProvider>
  );
}

export default App;
