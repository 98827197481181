import TableHeader from './listTable/TableHeader';
import { PaperClipIcon } from '@heroicons/react/24/solid';
import { FilePayload } from '../../api/methods/files';
import { getFormattedDateTime } from '../../lib/dateformat';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';
import { useState } from 'react';
import { useApi } from '../../api/APIContext';

interface FilesListTableProps {
  titles: string[];
  files: FilePayload[];
}

const LinkListWithFileIcon = ({ file }: { file: FilePayload }) => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const downloadfile = () => {
    setIsLoading(true);
    api.files
      .downloadFile(file.demandeId, file.id)
      .then(() => setIsLoading(false));
  };

  return (
    <div className={`grid w-full grid-flow-col auto-cols-fr border-b-2`}>
      <div className='px-4 py-2 min-w-20'>
        <div className='flex items-center'>
          <PaperClipIcon className='h-4 w-4 text-blue-900' />
          <p
            className='ml-2 space-x-1 hover:underline cursor-pointer'
            onClick={downloadfile}
            role={'link'}
          >
            <span>{file.fileName.split('.')[0]}</span>
            {isLoading && <Spinner />}
          </p>
        </div>
      </div>
      <div className='px-4 py-2 min-w-20'>
        <div className='flex items-center'>
          <p className='ml-2'>
            {getFormattedDateTime(file.createdAt, i18n.language)}
          </p>
        </div>
      </div>
    </div>
  );
};

const FilesListTable = ({ titles, files }: FilesListTableProps) => {
  const rowStyling = `grid w-full grid-flow-col auto-cols-fr `;

  return (
    <div key={`${titles}`} className='w-full'>
      <TableHeader key={`${titles}`} titles={titles} className={rowStyling} />
      {files.map((file) => (
        <LinkListWithFileIcon file={file} key={file.id} />
      ))}
    </div>
  );
};

export default FilesListTable;
