import Card from './containers/Card';
import Columns from './containers/Columns';
import { useTranslation } from 'react-i18next';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import { getFormattedDateTime } from '../lib/dateformat';

interface RequestDetailsProps {
  requestData?: DemandeGPAClientPayload;
}

const RequestDetails = ({ requestData }: RequestDetailsProps) => {
  const { t, i18n } = useTranslation();
  if (!requestData) return <></>;
  return (
    <Columns numOfCols={2}>
      <Card>
        {requestData.stage.step === 1 ? (
          <>
            <h2 className='paragraph font-bold'>
              {t('userCards.draft.title')}
            </h2>
            <p>{t('userCards.draft.text')}</p>
          </>
        ) : (
          <>
            <h2 className='paragraph font-bold'>
              {t('userCards.requestNumber')}
            </h2>
            <p>{requestData.fileNumber}</p>
            <h2 className='paragraph font-bold mt-2'>
              {t('userCards.requestDate')}
            </h2>
            <p>{getFormattedDateTime(requestData.createdAt, i18n.language)}</p>
          </>
        )}
      </Card>
      <Card>
        <h2 className='paragraph font-bold'>
          {requestData.isCouple
            ? t('userCards.coupleInfo')
            : t('userCards.userInfo')}
        </h2>
        <p className='mb-6'>
          {!requestData.isCouple || !requestData.parents?.[1]
            ? `${requestData.parents?.[0]?.firstName} ${requestData.parents?.[0]?.lastName}`
            : `${requestData.parents?.[0]?.firstName} ${requestData.parents?.[0]?.lastName} & ${requestData.parents?.[1]?.firstName} ${requestData.parents?.[1]?.lastName}`}
        </p>
        <a
          href={`/personalInfo?demandeId=${requestData.id}`}
          className='underline'
        >
          {t('userCards.modifyInfo')}
        </a>
      </Card>
    </Columns>
  );
};

export default RequestDetails;
