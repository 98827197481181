import { z } from 'zod';
import { translateLanguage } from './translateLanguage.utils';

export const defaultValues = {
  event: '',
  // event = 'surrogatePregnant'
  expectedDeliveryDate: '',
  sperm: [],
  egg: null,
  // event = 'terminatedPregnancy'
  terminationDate: '',
  // event = 'birth'
  numberOfChildren: 1,
  infantFields: [
    {
      lastName: '',
      firstName: '',
      sex: null,
      dob: '',
    },
  ],
  municipality: '',
  state: '',
  expectedReturnToQuebec: '',
};

export const surrogateNoticeSchema = (locale: string = 'fr') =>
  z.discriminatedUnion('event', [
    z.object({
      event: z.literal('surrogatePregnant'),
      expectedDeliveryDate: z
        .string()
        .min(1, translateLanguage('common.forms.required', locale)),
      sperm: z
        .enum(['parent', 'parent1', 'parent2', 'donor'])
        .array()
        .min(1, translateLanguage('common.forms.required', locale)),
      egg: z.enum(['parent1', 'parent2', 'surrogate', 'donor'], {
        message: translateLanguage('common.forms.required', locale),
      }),
    }),
    z.object({
      event: z.literal('terminatedPregnancy'),
      terminationDate: z
        .string()
        .min(1, translateLanguage('common.forms.required', locale)),
    }),
    z.object({
      event: z.literal('birth'),
      infantFields: z.array(
        z.object({
          firstName: z
            .string()
            .min(1, translateLanguage('common.forms.required', locale)),
          lastName: z
            .string()
            .min(1, translateLanguage('common.forms.required', locale)),
          sex: z.enum(['female', 'male', 'other'], {
            message: translateLanguage('common.forms.required', locale),
          }),
          dob: z
            .string()
            .min(1, translateLanguage('common.forms.required', locale)),
        })
      ),
      numberOfChildren: z.coerce.number().int().min(1).max(10),
      municipality: z
        .string()
        .min(1, translateLanguage('common.forms.required', locale)),
      state: z
        .string()
        .min(1, translateLanguage('common.forms.required', locale)),
      expectedReturnToQuebec: z
        .string()
        .min(1, translateLanguage('common.forms.required', locale)),
    }),
  ]);
