import dayjs from 'dayjs';
require('dayjs/locale/fr');

export function getFormattedDate(date: string | Date, locale: string): string {
  return dayjs(date)
    .locale(locale)
    .format(locale === 'en' ? 'MMM DD, YYYY' : 'DD MMMM YYYY');
}

export function getFormattedDateTime(
  date: string | Date,
  locale: string
): string {
  return dayjs(date)
    .locale(locale)
    .format(
      locale === 'en' ? 'MMM DD, YYYY [at] H:m a' : 'DD MMMM YYYY [à] HH [h] mm'
    );
}
