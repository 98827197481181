import { StepperStatus } from '../components/Stepper';

export const steps = (stepObjects: string[], currentIndex: number) => {
  return stepObjects.map((step: string, i: number) => {
    return {
      name: step,
      status:
        i < currentIndex
          ? StepperStatus.Complete
          : i === currentIndex
            ? StepperStatus.Current
            : StepperStatus.Upcoming,
    };
  });
};
