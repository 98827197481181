import classNames from '../../../utils/classList.utils';

interface TableHeaderProps {
  titles: string[];
  className: string;
}

const TableHeader = ({ titles, className }: TableHeaderProps) => {
  return (
    <div className={classNames('bg-blue-500', className)}>
      {titles.map((title, i) => (
        <p
          key={`${title}${i}`}
          className='px-4 py-2 font-semibold text-white min-w-20'
        >
          {title}
        </p>
      ))}
    </div>
  );
};

export default TableHeader;
