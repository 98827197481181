import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import TextField, { TextFieldProps } from '../TextInput';

interface RegisteredTextFieldProps<RegisteredTextFieldType extends FieldValues>
  extends TextFieldProps {
  name: Path<RegisteredTextFieldType>;
  register: UseFormRegister<RegisteredTextFieldType>;
  disabled?: boolean;
}

const RegisteredTextField = <RegisteredTextFieldType extends FieldValues>({
  register,
  disabled = false,
  ...props
}: RegisteredTextFieldProps<RegisteredTextFieldType>) => {
  const { ref, ...registerProps } = register(props.name);

  return (
    <TextField
      disabled={disabled}
      {...(props as TextFieldProps)}
      {...registerProps}
      textFieldRef={ref}
    />
  );
};

export default RegisteredTextField;
