import { dataverseFetch } from './http';

export interface FilePayload {
  fileName: string;
  step: number;
  id: string;
  createdAt: string;
  demandeId: string;
}

export const getFilesForDemande = async (demandeId: string) =>
  dataverseFetch<FilePayload[]>(`/demandes/${demandeId}/files`);

export const downloadFile = async (demandeId: string, fileId: string) => {
  const file = await dataverseFetch<{
    fileBase64Data: string;
    fileName: string;
  }>(`/demandes/${demandeId}/files/${fileId}`);
  const binaryString = window.atob(file.fileBase64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes.buffer]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = file.fileName;
  a.click();
};

export const uploadFile = async (
  demandeId: string,
  step: number,
  fileName: string,
  fileBase64Data: string
) => {
  await dataverseFetch<FilePayload>(`/demandes/${demandeId}/files`, {
    method: 'POST',
    body: JSON.stringify({
      name: fileName,
      step,
      file: fileBase64Data,
    }),
  });
};
