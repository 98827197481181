import { PropsWithChildren } from 'react';
import classNames from '../../utils/classList.utils';

interface SectionContainerProps {
  className?: string;
  id?: string;
  ref?: any;
}

const SectionContainer = ({
  children,
  id,
  className = '',
  ref,
}: PropsWithChildren<SectionContainerProps>) => {
  return (
    <div id={id} ref={ref} className={classNames('w-full my-10', className)}>
      {children}
    </div>
  );
};

export default SectionContainer;
