import { dataverseFetch } from './http';
import { getActiveDemandeId } from '../../utils/demande.utils';
import { PriorAuthPayload } from '../../utils/priorAuthForm.utils';
import { SignatureStatus } from '../../utils/types';

export interface PriorauthFormTypes {
  etats: ChoiceList[];
  statutconjugal: ChoiceList[];
  preuveCit: ChoiceList[];
  preuvededomicile: ChoiceList[];
  preuvedetatcivil: ChoiceList[];
  preuveunionconjugale: ChoiceList[];
  preuvevalidationpi: ChoiceList[];
  statutResidenceAuCanada: ChoiceList[];
}

export interface ParentAuthFormTypes {
  etats: ChoiceList[];
  preuvedecitoyenneteouresidenceperm: ChoiceList[];
  centredeprocreationassistelettre: ChoiceList[];
  preuvevalidationpi: ChoiceList[];
  preuvedomicile: ChoiceList[];
}

export const getPriorAuthFormTypes = async () =>
  dataverseFetch<PriorauthFormTypes>('/priorAuth/values');
export const getParentAuthFormTypes = async () =>
  dataverseFetch<ParentAuthFormTypes>('/parentAuth/values');
export const getDemandes = async () =>
  dataverseFetch<DemandeGPAClientPayload[]>('/demandes');

export const getActivedemande = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    return undefined;
  } else {
    return dataverseFetch<DemandeGPAClientPayload>(`/demandes/${demandeId}`);
  }
};

export const getParents = async () => {
  //demandeId is a queryparam in the current url
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    return [];
  } else {
    return dataverseFetch<ParentPayload[]>(`/demandes/${demandeId}/parents`);
  }
};

export const saveParent = async (parentId: string, parent: ParentPayload) => {
  //demandeId is a queryparam in the current url
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<ParentPayload>(
    `/demandes/${demandeId}/parents/${parentId}`,
    {
      method: 'PUT',
      body: JSON.stringify(parent),
    }
  );
};

export const savePiUnique = async (unique: boolean) => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch(`/linking/${demandeId}/setPIUnique`, {
    method: 'PUT',
    body: JSON.stringify({ unique }),
  });
};

export const getPIUnique = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<boolean>(`/linking/${demandeId}/getPIUnique`);
};

export const pingDemande = async () => {
  const demandeId = getActiveDemandeId();
  if (demandeId) {
    return dataverseFetch(`/demandes/${demandeId}/ping`);
  }
};

export const getPriorAuth = async () => {
  //demandeId is a queryparam in the current url
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<PriorAuthPayload>(`/demandes/${demandeId}/priorAuth`);
};

export const getParentAuth = async () => {
  //demandeId is a queryparam in the current url
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<ParentAuthForm>(`/demandes/${demandeId}/parentAuth`);
};
export const savePriorAuth = async (priorAuth: any) => {
  //demandeId is a queryparam in the current url
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<ParentPayload>(`/demandes/${demandeId}/priorAuth`, {
    method: 'PUT',
    body: JSON.stringify(priorAuth),
  });
};

export const saveParentAuth = async (parentAuth: any) => {
  //demandeId is a queryparam in the current url
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<ParentPayload>(`/demandes/${demandeId}/parentAuth`, {
    method: 'PUT',
    body: JSON.stringify(parentAuth),
  });
};

export const getLinkingInformation = async (): Promise<{
  code: string;
  isUnique: boolean;
  canLink: boolean;
}> => {
  //demandeId is a queryparam in the current url
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<{ code: string; isUnique: boolean; canLink: boolean }>(
    `/linking/${demandeId}`
  );
};

export const getPriorauthForDemande = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<PriorAuthClientPayload>(
    `/demandes/${demandeId}/priorAuth`
  );
};

export const getPriorAuthSigningStatus = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<SignatureStatus>(
    `/demandes/${demandeId}/priorAuth/signature`
  );
};

export const getParentAuthSigningStatus = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<SignatureStatus>(
    `/demandes/${demandeId}/parentAuth/signature`
  );
};

export const signPriorAuth = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<SignatureStatus>(
    `/demandes/${demandeId}/priorAuth/signature`,
    {
      method: 'POST',
    }
  );
};

export const signParentAuth = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    throw new Error('No demandeId found');
  }

  return dataverseFetch<SignatureStatus>(
    `/demandes/${demandeId}/parentAuth/signature`,
    {
      method: 'POST',
    }
  );
};

export const createDemande = async () =>
  dataverseFetch<{ demandeId: string }>('/demandes', { method: 'POST' });

export interface ParentPayload {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postalCode: string;
  dob: string;
  email?: string;
  telephone?: string;
  nam?: string;
}

export interface DemandeGPAClientPayload {
  id: string;
  fileNumber: string;
  createdAt: Date;
  modifiedAt: Date;
  stage: { step: number; status: 'draft' | 'submitted' | 'completed' };
  parents?: {
    firstName: string;
    lastName: string;
    id: string;
    isSelf: boolean;
  }[];
  isCouple: boolean;
  piUnique: boolean;
}

export interface PriorAuthClientPayload {
  scenario?: string;
  chosenState?: string;
  dateOfDeclaration?: string;
  declarationNumber?: string;
  marriageType?: string;
  parent1?: PriorAuthParent;
  parent2?: PriorAuthParent;
  marriageDocument?: string;
}

export interface PriorAuthParent {
  id?: string;
  firstName?: string;
  lastName?: string;
  residence?: string;
  idProof1?: CitizenshipProof;
  idProof2?: CitizenshipProof;
  residenceProofType1?: string;
  residenceProofType2?: string;
  citizenshipProof?: CitizenshipProof;
  certOfAttendanceDate?: string;
  criminalRecordCheckDate?: string;
  consent?: string;
  signature?: boolean;
}

export interface CitizenshipProof {
  docType?: string;
  expiration?: string;
}

export interface ParentAuthForm {
  changes: {
    status:
      | ''
      | 'undeterminedChanges'
      | 'noChanges'
      | 'hasMaritalStatusChanges'
      | 'hasResidenceChanges'
      | 'hasMaritalAndResidenceChanges';
    hasChanges: 'yes' | 'no' | '';
    changes: string[];
    changeAttachedDoc?: string;
    residenceChangeDoc?: string;
    parentChangeExplanation?: string;
  };

  surrogate: {
    lastName: string;
    firstName: string;
    dob: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    telephone: string;
    email: string;
  };

  documents: {
    idDocType: string;
    idDocExpiration: string;
    addressDocType: string;
    addressDocExpiration: string;
  };

  altruism: boolean;

  ethnicOrigin: string;
  education: string;
  diplomas: string;
  profession: string;

  height: string;
  eyeColour: string;
  skinColour: string;
  hairColour: string;
  personalityTraits: string;
  motivations: string;

  surrogateRelationship: {
    maritalStatus: '' | 'single' | 'civilUnion' | 'commonLaw' | 'married';
    spouseFamilyName?: string;
    spouseName?: string;
  };

  surrogateMaterialUsed: 'yes' | 'no';

  reproductiveMaterial: {
    parent1: 'ovocyte' | 'sperm' | 'none';
    parent2: 'ovocyte' | 'sperm' | 'none';
    needsDonationMaterial: 'yes' | 'no';
    donationMaterial?: 'egg' | 'sperm' | 'spermAndEgg';
  };
  reproductiveMaterialDocType: string;
  reproductiveMaterialDocExpiration: string;

  agreement: {
    agreementDate: string;
    language: {
      isInFrench: '' | 'yes' | 'no';
      agreementLanguage?: string;
    };
    reimbursement: {
      isReimbursed: '' | 'yes' | 'no';
      signDate?: string;
    };
    authorizationSignDate: string;
  };
}
