import RadioButton from '../RadioButton';
import { RadioGroupProps } from './types';

export default function RadioGroup({
  errorMessage,
  label,
  options,
  required,
  ...props
}: RadioGroupProps) {
  return (
    <div className='mb-4 pt-2'>
      <fieldset>
        <legend className='block mb-2 font-bold'>
          {label}
          {required && <span className='font-bold text-red-300'>&nbsp;*</span>}
        </legend>
        {options.map((option, i) => (
          <RadioButton
            key={i}
            {...props}
            {...option}
            hasError={!!errorMessage}
          />
        ))}
      </fieldset>
      {errorMessage && (
        <span className='mt-2 text-sm font-bold text-red-300'>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
