import { LegacyRef, useRef, useState } from 'react';
import classNames from '../utils/classList.utils';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import { ACCEPTED_IMAGE_TYPES } from '../utils/fileUploadForm.utils';

export interface FilePickerProps {
  label: string;
  errorMessage?: string;
  filePickerRef?: LegacyRef<HTMLInputElement>;
  details?: string;
  name: string;
  helpText?: string;
}

const FilePicker = ({
  label,
  errorMessage,
  filePickerRef,
  details,
  helpText,
  ...props
}: FilePickerProps) => {
  const { name } = props;
  const [fileName, setFileName] = useState('');
  const { t } = useTranslation();

  const labelRef = useRef<HTMLLabelElement>(null);

  const handleChange = (e: string) => {
    setFileName(e.split('fakepath')[1]);
  };

  const handleLabelClick = () => {
    labelRef.current?.click();
  };

  return (
    <div>
      <label htmlFor={name} className='block font-bold mb-2' ref={labelRef}>
        {label}
      </label>
      <div className='flex cursor-pointer'>
        <input
          type='text'
          value={fileName}
          readOnly
          className={classNames(
            'w-full paragraph',
            'cursor-pointer border-gray-300 h-10',
            'cursor-pointer border-gray-300 h-10',
            errorMessage ? 'border-red-300 border-2' : 'border-gray-300'
          )}
          onClick={handleLabelClick}
        />
        <Button
          label={t('common.selectFile')}
          primary
          onClick={handleLabelClick}
          className='shadow-none h-10'
          compact
        />
      </div>
      <p>{details}</p>
      <input
        id={name}
        type='file'
        accept={ACCEPTED_IMAGE_TYPES.join(',')}
        ref={filePickerRef}
        {...props}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        className='w-0 h-0'
      />
      {errorMessage ? (
        <p className='text-sm font-bold text-red-300'>{errorMessage}</p>
      ) : helpText ? (
        <p className='text-sm italic inline'>{helpText}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FilePicker;
