import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Form from '../../forms/Form';
import RegisteredCheckbox from '../../forms/registeredFields/RegisteredCheckbox';
import Checkbox from '../../forms/Checkbox';
import { useApi } from '../../api/APIContext';
import { useEffect, useState } from 'react';

const AddParentCard = ({
  intendedParent,
  code,
  isUnique,
  canLink,
}: {
  intendedParent: string;
  code: string;
  isUnique?: boolean;
  canLink?: boolean;
}) => {
  const { t } = useTranslation();
  const api = useApi();
  const { handleSubmit } = useForm();
  const [unique, setUnique] = useState<boolean | undefined>(undefined);
  const submitFn = (data: any) => {
    console.log(data);
  };

  const checkboxChange = (e: any) => {
    if (!canLink) return;
    const checked = !!e.target.checked;

    setUnique(checked);
    api.demandes.savePiUnique(checked).then();
  };

  useEffect(() => {
    setUnique(isUnique);
  }, [isUnique]);

  return (
    <Form onSubmit={handleSubmit(submitFn)}>
      {isUnique != undefined && (
        <Checkbox
          label={t('personalInfo.addParentForm.checkbox')}
          onChange={checkboxChange}
          defaultChecked={isUnique}
          disabled={!canLink}
        />
      )}
      {!canLink || unique || unique === undefined ? (
        <></>
      ) : (
        <>
          <ReactMarkdown>
            {t('personalInfo.addParentForm.paragraph')}
          </ReactMarkdown>
          <p className='mt-5 font-bold'>
            {t('personalInfo.addParentForm.list.heading')}
          </p>
          <ReactMarkdown>
            {t('personalInfo.addParentForm.list.items')}
          </ReactMarkdown>
          <div className='m-8'>
            <p className='font-bold'>
              {t('personalInfo.addParentForm.joinInfo.code')}{' '}
              <span className='font-normal'>{code}</span>
            </p>
            <p className='font-bold'>
              {t('personalInfo.addParentForm.joinInfo.name')}{' '}
              <span className='font-normal'>{intendedParent}</span>
            </p>
          </div>
        </>
      )}
      {/*<div className='p-6 bg-gray-100 border'>*/}
      {/*  <ReactMarkdown className='mb-6 whitespace-pre-wrap'>*/}
      {/*    {t('personalInfo.addParentForm.card.paragraph', {*/}
      {/*      name: 'Dominique Beaumier',*/}
      {/*      birthday: 'le 6 juin 2024 à 14:15',*/}
      {/*    })}*/}
      {/*  </ReactMarkdown>*/}
      {/*  <div className='flex flex-col justify-center m-auto my-5'>*/}
      {/*    <Button*/}
      {/*      onClick={() => {}}*/}
      {/*      className='mb-5'*/}
      {/*      label={t('personalInfo.addParentForm.card.accept')}*/}
      {/*      primary*/}
      {/*    />*/}
      {/*    <Button*/}
      {/*      onClick={() => {}}*/}
      {/*      label={t('personalInfo.addParentForm.card.refuse')}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Form>
  );
};

export default AddParentCard;
