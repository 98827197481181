import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import RadioGroup from '../RadioGroup';
import { RadioGroupProps } from '../RadioGroup/types';

interface RegisteredRadioGroupProps<
  RegisteredRadioGroupType extends FieldValues,
> extends RadioGroupProps {
  name: Path<RegisteredRadioGroupType>;
  register: UseFormRegister<RegisteredRadioGroupType>;
}

const RegisteredRadioGroup = <RegisteredRadioGroupType extends FieldValues>({
  register,
  ...props
}: RegisteredRadioGroupProps<RegisteredRadioGroupType>) => {
  const { ref, ...registerProps } = register(props.name);

  return <RadioGroup {...props} {...registerProps} radioRef={ref} />;
};

export default RegisteredRadioGroup;
