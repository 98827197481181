import { useTranslation } from 'react-i18next';
import SectionContainer from './containers/SectionContainer';
import Pill from './Pill';
import ListTable from './containers/listTable/ListTable';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import theme from '../lib/theme';

const stateToStatus = (state: string) => {
  switch (state) {
    case 'À compléter':
      return 'warning';
    case 'Complété':
      return 'success';
    default:
      return 'default';
  }
};

//     {
//   files,
// }: {
//   files: { name: string; state: string }[];
// }
const FormCompletion = ({
  step,
  demande,
}: {
  step: number;
  demande: string;
}) => {
  const { t } = useTranslation();
  const availableFiles = {
    2: [
      {
        name: "Formulaire de demande d'autorisation préalable",
        link: (
          <a
            href={`/priorAuth?demandeId=${demande}`}
            className='flex justify-between'
          >
            <span className='flex-grow inline-block md:white'>
              Voir le formulaire
            </span>
            <ArrowLongRightIcon
              className='float-right w-6'
              color={theme.colors.red[200]}
            />
          </a>
        ),
        state: '',
      },
    ],
    3: [
      {
        name: "Formulaire de demande d'autorisation du projet parental",
        link: (
          <a
            href={`/parentAuth?demandeId=${demande}`}
            className='flex justify-between'
          >
            <span className='flex-grow inline-block md:white'>
              Voir le formulaire
            </span>
            <ArrowLongRightIcon
              className='float-right w-6'
              color={theme.colors.red[200]}
            />
          </a>
        ),
        state: '',
      },
    ],
  };

  const files = availableFiles[step as keyof typeof availableFiles] || [];

  return (
    <SectionContainer>
      <div className='flex items-center justify-between mt-16 mb-10'>
        <h2 className='heading2 my-0'>{t('dashboard.formCompletion.title')}</h2>
      </div>
      <ListTable
        titles={[
          t('dashboard.formCompletion.tableHeaders.form'),
          // t('dashboard.formCompletion.tableHeaders.status'),
          '',
        ]}
        elements2D={[
          ...files.map((file) => [
            <p>{file.name}</p>,
            file.link,
            // <Pill type={stateToStatus(file.state)} text={file.state} />,
          ]),
        ]}
      />
      {step > 2 && step < 7 && (
        <>
          <div className='flex flex-col mt-16 mb-10'>
            <h2 className='heading2 my-0'>Demande de changement</h2>
            <p>
              Lorsqu'applicable vous devez transmettre une demande de changement
            </p>
          </div>
          <ListTable
            titles={[
              t('dashboard.formCompletion.tableHeaders.form'),
              // t('dashboard.formCompletion.tableHeaders.status'),
              '',
            ]}
            elements2D={[
              [
                <p>Formulaire de demande de changement au projet parental</p>,
                <a
                  href={`/changeRequest?demandeId=${demande}`}
                  className='flex justify-between'
                >
                  <span className='flex-grow inline-block md:white'>
                    Voir le formulaire
                  </span>
                  <ArrowLongRightIcon
                    className='float-right w-6'
                    color={theme.colors.red[200]}
                  />
                </a>,
                // <Pill type={stateToStatus(file.state)} text={file.state} />,
              ],
            ]}
          />
        </>
      )}
    </SectionContainer>
  );
};

export default FormCompletion;
