import classNames from '../../utils/classList.utils';
import { StepperStatus } from './index';
import { useTranslation } from 'react-i18next';

interface StepProps {
  step: {
    name: string;
    status: string;
  };
  stepIdx: number;
  status: StepperStatus;
  stepNum: number;
  reqStatus: 'draft' | 'confirmed';
}

const Step = ({ step, stepIdx, status, stepNum, reqStatus }: StepProps) => {
  const { t } = useTranslation();
  const isCurrentStep = status === StepperStatus.Current;
  const isCompletedStep = status === StepperStatus.Complete;
  const isDraftRequest = reqStatus === 'draft';

  const textStyles =
    'paragraph lg:text-center text-blue-400 lg:mb-3 lg:mb-1 lg:left-auto left-12';
  const lineStyles =
    'absolute inset-0 flex items-center 2xl:top-[7.5rem] lg:top-[10.5rem] top-0 lg:left-0 left-5 lg:h-1.5 h-full w-1.5 bg-blue-400';

  return (
    <div
      className={classNames(
        'lg:mb-2 p-3 lg:pb-14 flex flex-col lg:items-center h-full',
        stepNum === stepIdx + 1 ? 'bg-blue-100 xl:mx-2' : 'bg-white'
      )}
    >
      <p
        className={classNames(
          textStyles,
          stepNum === stepIdx + 1 ? 'font-semibold' : '',
          'lg:relative absolute lg:top-auto 2xl:min-h-20 lg:min-h-32 min-h-0',
          isCurrentStep && !isDraftRequest ? 'top-3' : 'top-3'
        )}
      >
        {step.name}
      </p>
      <div
        aria-hidden='true'
        className={classNames(
          lineStyles,
          'xl:w-1/2 lg:w-1/3 z-10',
          isCurrentStep || isCompletedStep ? 'bg-blue-400' : 'bg-gray-200',
          isDraftRequest ? '!bg-blue-400' : ''
        )}
      />
      <div
        aria-hidden='true'
        className={classNames(
          lineStyles,
          'lg:w-full',
          isCompletedStep ? 'bg-blue-400' : 'bg-gray-200',
          isDraftRequest ? '!bg-blue-400' : ''
        )}
      />
      <span
        className={classNames(
          'relative h-6 w-6 flex items-center justify-center rounded-full mt-auto text-sm font-semibold font-openSans z-20',
          isCurrentStep
            ? 'bg-blue-400 text-white'
            : 'bg-gray-200 text-grey-500',
          isCompletedStep
            ? 'bg-white text-blue-500 border-2 border-blue-400'
            : '',
          isDraftRequest ? '!bg-blue-400 text-white' : ''
        )}
      >
        {stepIdx + 1}
      </span>
      {!isDraftRequest && isCurrentStep && (
        <p
          className={classNames(
            textStyles,
            'lg:absolute lg:bottom-2 relative lg:h-5 left-9',
            stepNum === stepIdx + 1 ? 'font-semibold' : ''
          )}
        >
          {t('common.inProgress')}
        </p>
      )}
    </div>
  );
};

export default Step;
