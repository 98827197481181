import React, { FormEvent, LegacyRef, PropsWithChildren } from 'react';

interface FormProps {
  formRef?: LegacyRef<HTMLFormElement>;
  onSubmit?: (e: FormEvent) => void;
}

const Form = ({
  children,
  formRef,
  onSubmit,
}: PropsWithChildren<FormProps>) => {
  return (
    // Making sure the RHF/Yup validation takes precedence over native browser validation
    <form ref={formRef} noValidate onSubmit={onSubmit} className='mt-2'>
      {children}
    </form>
  );
};

export default Form;
