import r2wc from '@r2wc/react-to-web-component';
import App from './App';
import './index.css';
import './i18n';

//Cette fonction exporte le composant principal (App) en Web Component
const wcGPAHQApp = r2wc(App, {
  props: {
    path: 'string',
    language: 'string',
    oauthclientid: 'string',
    oauthurl: 'string', // changed from 'oauthUrl'
    azfunctionsurl: 'string',
    appurl: 'string',
  },
});

customElements.define('gpa-hq-app', wcGPAHQApp);
