import { TFunction } from 'i18next';
import { z } from 'zod';
import { translateLanguage } from './translateLanguage.utils';

const booleanArray = ['yes', 'no', ''] as const;

const ParentMaterial = ['ovocyte', 'sperm', 'none'] as const;

const DonorMaterial = ['egg', 'sperm', 'spermAndEgg'] as const;

export const parentAuthDefaultValues = {
  changes: {
    status: '', // derived discriminator
    hasChanges: '', // 1st discriminator
    changes: [] as string[], // 2nd discriminator "maritalStatus", "residence", "other"
    changeAttachedDoc: '', // if changes.includes("maritalStatus")
    residenceChangeDoc: '', // if changes.includes("residence")
    parentChangeExplanation: '', // if changes.includes("residence")
  },

  surrogate: {
    lastName: '',
    firstName: '',
    dob: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    telephone: '',
    email: '',
  },

  documents: {
    idDocType: '',
    idDocExpiration: '',
    addressDocType: '',
    addressDocExpiration: '',
  },

  altruism: false,

  ethnicOrigin: '',
  education: '',
  diplomas: '',
  profession: '',

  height: '',
  eyeColour: '',
  skinColour: '',
  hairColour: '',
  personalityTraits: '',
  motivations: '',

  surrogateRelationship: {
    maritalStatus: '', // discriminator,
    spouseFamilyName: '', // if marital status is NOT "single"
    spouseName: '', // if marital status is NOT "single"
  },

  surrogateMaterialUsed: '',

  reproductiveMaterial: {
    parent1: '', // discriminator
    parent2: '', // discriminator
    needsDonationMaterial: 'yes', // derived discriminator
    donationMaterial: '', // conditionally rendered
  },
  reproductiveMaterialDocType: '',
  reproductiveMaterialDocExpiration: '',

  agreement: {
    agreementDate: '',
    language: {
      isInFrench: '',
      agreementLanguage: '',
    },
    reimbursement: {
      isReimbursed: '',
      signDate: '',
    },
    authorizationSignDate: '',
  },
};

export const parentAuthSchema = (locale: string = 'fr') =>
  z.object({
    changes: z
      .discriminatedUnion('status', [
        z.object({
          status: z.literal(''),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
        z.object({
          status: z.literal('undeterminedChanges'),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
        }),
        z.object({
          status: z.literal('noChanges'),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
        }),

        z.object({
          status: z.literal('hasMaritalStatusChanges'),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
          changeAttachedDoc: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
        z.object({
          status: z.literal('hasResidenceChanges'),
          hasChanges: z.enum(booleanArray, {
            message: translateLanguage('common.forms.required', locale),
          }),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
          residenceChangeDoc: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
          parentChangeExplanation: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
        z.object({
          status: z.literal('hasMaritalAndResidenceChanges'),
          hasChanges: z.enum(booleanArray),
          changes: z
            .string()
            .array()
            .min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
          changeAttachedDoc: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
          residenceChangeDoc: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
          parentChangeExplanation: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
      ])
      .superRefine((val, ctx) => {
        if (val.status === '' && !val.hasChanges) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['hasChanges'],
            message: translateLanguage('common.forms.required', locale),
          });
        }
      }),

    surrogateRelationship: z
      .discriminatedUnion('maritalStatus', [
        z.object({
          maritalStatus: z.literal(''),
        }),
        z.object({
          maritalStatus: z.literal('single'),
        }),
        z.object({
          maritalStatus: z.literal('civilUnion'),
          spouseFamilyName: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
          spouseName: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
        z.object({
          maritalStatus: z.literal('commonLaw'),
          spouseFamilyName: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
          spouseName: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
        z.object({
          maritalStatus: z.literal('married'),
          spouseFamilyName: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
          spouseName: z.string().min(1, {
            message: translateLanguage('common.forms.required', locale),
          }),
        }),
      ])
      .superRefine((val, ctx) => {
        if (val.maritalStatus === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['maritalStatus'],
            message: translateLanguage('common.forms.required', locale),
          });
        }
      }),

    surrogate: z.object({
      firstName: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      lastName: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      dob: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      address: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      city: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      state: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      postalCode: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      telephone: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      email: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
    }),

    documents: z.object({
      idDocType: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      idDocExpiration: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      addressDocType: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      addressDocExpiration: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
    }),

    altruism: z.literal(true, {
      errorMap: () => ({
        message: translateLanguage('common.forms.required', locale),
      }),
    }),

    ethnicOrigin: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    education: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    diplomas: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    profession: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),

    height: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    eyeColour: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    skinColour: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    hairColour: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    personalityTraits: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    motivations: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),

    surrogateMaterialUsed: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),

    reproductiveMaterial: z.discriminatedUnion('needsDonationMaterial', [
      z.object({
        parent1: z.enum(ParentMaterial, {
          message: translateLanguage('common.forms.required', locale),
        }),
        parent2: z.enum(ParentMaterial, {
          message: translateLanguage('common.forms.required', locale),
        }),
        needsDonationMaterial: z.literal('no'),
      }),
      z.object({
        parent1: z.enum(ParentMaterial, {
          message: translateLanguage('common.forms.required', locale),
        }),
        parent2: z.enum(ParentMaterial, {
          message: translateLanguage('common.forms.required', locale),
        }),
        needsDonationMaterial: z.literal('yes'),
        donationMaterial: z.enum(DonorMaterial, {
          message: translateLanguage('common.forms.required', locale),
        }),
      }),
    ]),

    reproductiveMaterialDocType: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),
    reproductiveMaterialDocExpiration: z.string().min(1, {
      message: translateLanguage('common.forms.required', locale),
    }),

    agreement: z.object({
      agreementDate: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
      language: z
        .discriminatedUnion('isInFrench', [
          z.object({
            isInFrench: z.literal(''),
          }),
          z.object({
            isInFrench: z.literal('yes'),
          }),
          z.object({
            isInFrench: z.literal('no'),
            agreementLanguage: z.string().min(1, {
              message: translateLanguage('common.forms.required', locale),
            }),
          }),
        ])
        .superRefine((val, ctx) => {
          if (val.isInFrench === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['isInFrench'],
              message: translateLanguage('common.forms.required', locale),
            });
          }
        }),
      reimbursement: z
        .discriminatedUnion('isReimbursed', [
          z.object({
            isReimbursed: z.literal(''),
          }),
          z.object({
            isReimbursed: z.literal('yes'),
          }),
          z.object({
            isReimbursed: z.literal('no'),
            signDate: z.string().min(1, {
              message: translateLanguage('common.forms.required', locale),
            }), // This doesn't currently do any date validation, might need to be updated
          }),
        ])
        .superRefine((val, ctx) => {
          if (val.isReimbursed === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['isReimbursed'],
              message: translateLanguage('common.forms.required', locale),
            });
          }
        }),
      authorizationSignDate: z.string().min(1, {
        message: translateLanguage('common.forms.required', locale),
      }),
    }),
  });

export type ParentAuthForm = z.infer<ReturnType<typeof parentAuthSchema>>;

export const getParentReproductiveMaterialUsedOptions = (
  t: TFunction<'translation', undefined>,
  surrogateOvocyte?: boolean
) =>
  [
    {
      value: 'sperm',
      label: t(
        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.sperm'
      ),
    },
    {
      value: 'ovocyte',
      label: t(
        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.ovocyte'
      ),
    },
    {
      value: 'none',
      label: t(
        'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.none'
      ),
    },
  ].filter((e) => (surrogateOvocyte ? e.value !== 'ovocyte' : true));

export const getGametesOptions = (
  surrogateOvocyte: boolean,
  spermatozoide: boolean,
  t?: TFunction<'translation', undefined>
) =>
  [
    {
      value: 'sperm',
      label: t
        ? t(
            'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.options.sperm'
          )
        : 'sperm',
    },
    {
      value: 'ovocyte',
      label: t
        ? t(
            'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.options.ovocyte'
          )
        : 'ovocyte',
    },
    {
      value: 'spermAndEgg',
      label: t
        ? t(
            'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.options.spermAndEgg'
          )
        : 'spermAndEgg',
    },
  ]
    .filter((e) => (surrogateOvocyte ? e.value !== 'ovocyte' : true))
    .filter((e) => (spermatozoide ? e.value !== 'sperm' : true))
    .filter((e) =>
      spermatozoide || surrogateOvocyte ? e.value !== 'spermAndEgg' : true
    );
