import ListTable from './containers/listTable/ListTable';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import Button from '../forms/Button';
import ReactMarkdown from 'react-markdown';
import SectionContainer from './containers/SectionContainer';
import { useApi } from '../api/APIContext';
import { useEffect, useState } from 'react';
import { DemandeMessage } from '../api/methods/messages';
import { truncateString } from '../utils/string.utils';
import { getFormattedDateTime } from '../lib/dateformat';
import i18n from '../i18n';
import Spinner from './Spinner';

interface MessagesTableProps {
  demandeId: string;
}

const messageUrl = (id: string) => `/message?messageId=${id}`;

const messagePropDisplay = {
  subject: ({ subject, id }: DemandeMessage) => (
    <a href={messageUrl(id)}>
      <p>{truncateString(subject, 50)}</p>
    </a>
  ),
  message: ({ message, id }: DemandeMessage) => (
    <a href={messageUrl(id)}>
      <p>{truncateString(message, 50)}</p>
    </a>
  ),
  sender: ({ sender, id }: DemandeMessage) => (
    <a href={messageUrl(id)}>
      <p>{truncateString(sender, 50)}</p>
    </a>
  ),
  createdAt: ({ createdAt, id }: DemandeMessage) => (
    <a href={messageUrl(id)}>
      <p>{getFormattedDateTime(createdAt, i18n.language)}</p>
    </a>
  ),
} as const;

function convertToArray(message: DemandeMessage) {
  return (['sender', 'subject', 'createdAt'] as const).map((k) =>
    messagePropDisplay[k](message)
  );
}

const MessagesTable = ({ demandeId }: MessagesTableProps) => {
  const { t } = useTranslation();
  const api = useApi();

  const [messages, setMessages] = useState<DemandeMessage[] | null>(null);

  useEffect(() => {
    api.messages
      .getMessages(demandeId)
      .then((messages) => {
        console.log(messages);
        setMessages(
          messages?.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }, [api, demandeId]);

  return !messages ? (
    <Spinner />
  ) : (
    <SectionContainer>
      <div className='flex items-center justify-between mt-16 mb-10'>
        <h2 className='heading2 my-0'>{t('demandeMessages.messages')}</h2>
        <Button
          href={`/newMessage?demandeId=${demandeId}`}
          icon={<EnvelopeIcon width={20} height={20} />}
          label={t('demandeMessages.sendNewMessage')}
          className='inline ml-auto'
        />
      </div>
      <ListTable
        titles={[
          t('demandeMessages.sentBy'),
          t('demandeMessages.subject'),
          t('demandeMessages.sentOn'),
        ]}
        elements2D={messages.map(convertToArray)}
      />
      <ReactMarkdown
        className='mt-4'
        components={{
          p: ({ ...props }) => (
            <p {...props} className='text-sm'>
              {props.children}
            </p>
          ),
        }}
      >
        {t('demandeMessages.orderNote')}
      </ReactMarkdown>
    </SectionContainer>
  );
};

export default MessagesTable;
