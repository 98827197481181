import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '../forms/Button';
import Columns from './containers/Columns';
import RegisteredFilePicker from '../forms/registeredFields/RegisteredFilePicker';
import { useForm } from 'react-hook-form';
import SectionContainer from './containers/SectionContainer';
import Form from '../forms/Form';
import FilesListTable from './containers/FilesListTable';
import { FilePayload } from '../api/methods/files';
import { useEffect, useState } from 'react';
import { useApi } from '../api/APIContext';
import Spinner from './Spinner';

const Files = ({ step, demandeId }: { step: number; demandeId: string }) => {
  // const s = step;
  const { t } = useTranslation();
  const api = useApi();
  const [files, setFiles] = useState<FilePayload[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const refreshFiles = () => {
    api?.files.getFilesForDemande(demandeId).then(setFiles);
  };

  useEffect(() => {
    api?.files.getFilesForDemande(demandeId).then(setFiles);
  }, [api?.files, demandeId]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    // resolver: zodResolver(generateFileUploadSchema(i18n.language)),
    //TODO: Fix this, right now Zod is not letting go through files that match the conditions
    //disabling for now
  });

  const submitFn = (data: any) => {
    console.log(data.file);
    if (!data.file) return;
    setIsLoading(true);
    const file: FileList = data.file;

    if (file.length === 0) return setIsLoading(false);

    //base64 file
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target?.result;
      const base64 = fileData?.toString().split(',')[1];
      if (!base64) return setIsLoading(false);

      api.files
        .uploadFile(demandeId, step, file[0].name, base64)
        .then(() => refreshFiles())
        .then(() => setIsLoading(false))
        //reset form
        .then(() => {
          reset();
        });
    };
    reader.readAsDataURL(file[0]);
  };

  return (
    <SectionContainer>
      <h2 className='heading2 mt-20'>{t('dashboard.files.form.title')}</h2>
      <FilesListTable
        titles={[
          t('dashboard.files.tableHeaders.file'),
          t('dashboard.files.tableHeaders.sentDate'),
        ]}
        files={files.filter((f) => f.step === step || f.step === 0)}
      />
      <h3 className='heading3'>{t('dashboard.files.form.uploadFile')}</h3>
      <Form onSubmit={handleSubmit(submitFn)}>
        <Columns numOfCols={2} className='mb-8'>
          <RegisteredFilePicker
            name={'file'}
            register={register}
            label={t('dashboard.files.form.file')}
            errorMessage={errors?.file?.message?.toString()}
            helpText={t('dashboard.files.form.allowedType')}
          />
        </Columns>
        {!watch('file')}
        <Button
          type={ButtonTypes.submit}
          className='inline ml-auto'
          primary
          disabled={isLoading || !watch('file')}
          label={t('dashboard.files.form.button')}
          icon={isLoading ? <Spinner /> : undefined}
        />
      </Form>
    </SectionContainer>
  );
};

export default Files;
