import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import Select, { SelectProps } from '../Select';

interface RegisteredSelectProps<RegisteredSelectType extends FieldValues>
  extends SelectProps {
  name: Path<RegisteredSelectType>;
  register: UseFormRegister<RegisteredSelectType>;
}

const RegisteredSelect = <RegisteredSelectType extends FieldValues>({
  register,
  ...props
}: RegisteredSelectProps<RegisteredSelectType>) => {
  const { ref, ...registerProps } = register(props.name);

  return (
    <Select {...(props as SelectProps)} {...registerProps} selectRef={ref} />
  );
};

export default RegisteredSelect;
