import { RefObject } from 'react';

const readFileAsDataURL = (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64 = reader.result?.toString().split(',')[1];

      resolve(base64); // resolve with base64 data
    };
    reader.onerror = reject; // reject on error
  });
};
export const generatePDF = async (toPDF: any) => {
  const p = new Promise((resolve, reject) => {
    try {
      toPDF().then((pdf: any) => {
        // const dataUri = pdf.output('datauri');
        var reader = new FileReader();
        reader.readAsDataURL(pdf.output('blob'));
        reader.onloadend = function () {
          //download the pdf
          var base64data = reader.result;
          resolve(base64data);
        };
      });
    } catch (e) {
      reject(e);
    }
  });
  return p;
};

export const downloadHTML = (documentTitle: string) => {
  const element = document?.querySelector('.exportable-form');
  if (!element) {
    return;
  }
  const inputs = element.querySelectorAll('input, textarea, select');
  const labels = document.querySelectorAll('label');

  inputs.forEach((input) => {
    if (input instanceof HTMLInputElement) {
      if (input.type === 'checkbox' || input.type === 'radio') {
        input.checked
          ? input.setAttribute('checked', 'true')
          : input.removeAttribute('checked');
      } else {
        replaceElementWithText(input, `${input.value}`);
      }
    } else if (input instanceof HTMLTextAreaElement) {
      replaceElementWithText(input, `${input.value}`);
    } else if (input instanceof HTMLSelectElement) {
      const options = Array.from(input.options);
      const selectedOption = options.find((o) => o.value == input.value);
      console.log(selectedOption, input.options, input.value);
      replaceElementWithText(
        input,
        `${selectedOption ? selectedOption.innerText : ''}`
      );
    }
  });

  function getLabelForInput(
    input: HTMLElement,
    labels: NodeListOf<HTMLLabelElement>
  ): string {
    const id = input.id;
    if (id) {
      const label = Array.from(labels).find((lbl) => lbl.htmlFor === id);
      if (label) {
        return label.textContent || '';
      }
    }
    return '';
  }

  function replaceElementWithText(element: HTMLElement, text: string) {
    const textNode = document.createTextNode(text);
    element.replaceWith(textNode);
  }

  const styleSheets = document.styleSheets;

  let allStyles = '';

  for (let i = 0; i < styleSheets.length; i++) {
    try {
      const styleSheet = styleSheets[i];

      const rules = styleSheet.cssRules;

      for (let j = 0; j < rules.length; j++) {
        const rule = rules[j];

        allStyles += rule.cssText + '\n';
      }
    } catch (e) {}
  }

  const htmlString = `
      <!DOCTYPE html>
      <html>
        <head>
        <style>${allStyles}</style>
          <title>${documentTitle || 'GPA HQ'}</title>
        </head>
        <body>
<div class="w-full h-4 font-bold text-md text-center text-red-300">${new Date().toISOString()}</div>
${element.outerHTML}</body>
      </html>
    `;

  const blob = new Blob([htmlString], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  return readFileAsDataURL(blob);
};
