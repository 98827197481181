import { ChangeEvent, LegacyRef } from 'react';
import classNames from '../utils/classList.utils';

export interface CheckboxProps {
  checkboxRef?: LegacyRef<HTMLInputElement>;
  disabled?: boolean;
  label: string;
  name?: string;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  description?: string;
  value?: string;
  parentHasError?: boolean;
  errorMessage?: string;
  defaultChecked?: boolean;
}

export default function Checkbox({
  checkboxRef,
  label,
  parentHasError,
  errorMessage,
  ...props
}: CheckboxProps) {
  const { disabled, required, description, value } = props;
  console.log('Checkbox', props.defaultChecked);
  return (
    <div className='flex items-start gap-3 my-5'>
      <input
        {...props}
        ref={checkboxRef}
        id={value}
        type='checkbox'
        className={classNames(
          'w-6 h-6 mt-1 text-blue-300 border-2 cursor-pointer focus:ring-2 focus:ring-blue-200 disabled:border-gray-400',
          parentHasError || errorMessage ? 'border-red-300' : 'border-blue-600'
        )}
      />
      <label htmlFor={value} className={disabled ? 'text-gray-400' : ''}>
        <span>
          {label} {props.defaultChecked}
          {required && <span className='font-bold text-red-300'>*&nbsp;</span>}
          {errorMessage && (
            <p className='mt-1 text-sm font-bold text-red-300'>
              {errorMessage}
            </p>
          )}
        </span>
        {description && <p className='block text-sm'>{description}</p>}
      </label>
    </div>
  );
}
