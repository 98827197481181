import { Fragment, ReactNode } from 'react';
import classNames from '../../utils/classList.utils';

interface ColumnProps {
  numOfCols: 2 | 3;
  children: ReactNode | ReactNode[];
  className?: string;
}

const Columns = ({ numOfCols, children, className = '' }: ColumnProps) => {
  return (
    <div
      className={classNames(
        `grid md:grid-cols-${numOfCols} grid-cols-1 gap-4`,
        className
      )}
    >
      {Array.isArray(children) ? (
        children.map((child, index) => <Fragment key={index}>{child}</Fragment>)
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </div>
  );
};

export default Columns;
