export default function choiceTypeToSelectOptions(
  choiceTypes?: ChoiceList[],
  language?: string
) {
  return (
    choiceTypes?.map((type) => ({
      value: type.id,
      label: language === 'en' ? type.label_en : type.label_fr,
    })) || []
  );
}
