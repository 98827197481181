import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import LinkList from '../components/LinkList';
import Accordion from '../components/containers/Accordion';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredDateField from '../forms/registeredFields/RegisteredDateField';
import RegisteredRadioGroup from '../forms/registeredFields/RegisteredRadioGroup';
import RegisteredSelect from '../forms/registeredFields/RegisteredSelect';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import { consentSelection } from '../mockData/priorAuthFormMockData';
import React, { useEffect, useRef, useState } from 'react';
import { Margin, usePDF } from 'react-to-pdf';
import { useApi } from '../api/APIContext';
import {
  DemandeGPAClientPayload,
  getPriorAuthSigningStatus,
  PriorauthFormTypes,
  savePriorAuth,
  signPriorAuth,
} from '../api/methods/demandes';
import RequestDetails from '../components/RequestDetails';
import choiceTypeToSelectOptions from '../lib/choiceTypeToSelectOptions';
import BackButton from '../components/BackButton';
import { SignatureStatus } from '../utils/types';
import Pill from '../components/Pill';
import { getFormattedDateTime } from '../lib/dateformat';
import { downloadHTML } from '../utils/pageToPdf';

const PriorAuth = () => {
  const { t, i18n } = useTranslation();

  const api = useApi();
  const [demande, setDemande] = useState<DemandeGPAClientPayload>();

  const [priorAuthFormTypes, setPriorAuthFormTypes] =
    useState<PriorauthFormTypes>();
  const {
    handleSubmit,
    register,
    unregister,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    // resolver: zodResolver(generatePriorAuthSchema(i18n.language)),
    defaultValues: async () => api.demandes.getPriorauthForDemande(),
  });

  const targetRef = useRef<HTMLDivElement>();
  const marriageType = watch('marriageType');

  const [signingStatus, setSigningStatus] = useState<SignatureStatus>();

  const selfParent = demande?.parents?.find((p) => p.isSelf);
  const otherParent =
    !!demande?.parents?.[1] && demande?.parents?.find((p) => !p.isSelf);
  const selfPINumber = (demande?.parents?.findIndex((p) => p.isSelf) ?? -1) + 1;
  const selfParentHasSigned =
    selfPINumber == 1 ? signingStatus?.signed_pi1 : signingStatus?.signed_pi2;
  const otherPINumber =
    (demande?.parents?.findIndex((p) => !p.isSelf) ?? -1) + 1;
  const otherParentHasSigned =
    otherPINumber > 0
      ? otherPINumber == 1
        ? signingStatus?.signed_pi1
        : signingStatus?.signed_pi2
      : undefined;

  const someoneHasSigned = !!selfParentHasSigned || !!otherParentHasSigned;

  useEffect(() => {
    if (demande?.parents?.[1]) {
      switch (marriageType) {
        case 'commonLaw':
          unregister('marriageDocument');
          setValue('scenario', 'coupleCommonlaw');
          break;
        case 'marriage':
          setValue('scenario', 'coupleMarriage');
          break;
        case 'civilUnion':
          setValue('scenario', 'coupleMarriage');
          break;
        default:
          setValue('scenario', 'coupleCommonlaw');
          unregister('marriageDocument');
      }
    }
  }, [marriageType, demande?.parents?.[1], setValue, unregister]);

  const links: { label: string; href: string }[] = t('priorAuth.links.items', {
    returnObjects: true,
  });

  const getParent = (num: 1 | 2) => {
    const parent1Names = getValues([
      `parent${num}.firstName`,
      `parent${num}.lastName`,
    ]);
    return `${parent1Names[0]} ${parent1Names[1]}`;
  };

  const getSigningStatus = async (
    previouslySignedNumber?: number,
    requiredNumber?: number
  ) => {
    const signing = await api.demandes.getPriorAuthSigningStatus();
    setSigningStatus(signing);
    let numberSigned = 0;
    numberSigned += signing?.signed_pi1 ? 1 : 0;
    numberSigned += signing?.signed_pi2 ? 1 : 0;

    if (
      previouslySignedNumber &&
      requiredNumber &&
      previouslySignedNumber != requiredNumber &&
      numberSigned == requiredNumber
    ) {
      //wait 1s as a promise
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const html = await downloadHTML(t('priorAuth.title'));
      if (!html) return;
      await api.files.uploadFile(
        demande?.id || '',
        demande?.stage?.step || 2,
        t('priorAuth.title') + '.html',
        html
      );
    }
  };

  useEffect(() => {
    api.demandes.getActivedemande().then(setDemande);
    api.demandes.getPriorAuthFormTypes().then(setPriorAuthFormTypes);
    getSigningStatus().then();
    // i18n.changeLanguage('en');
  }, []);

  const sign = async () => {
    const html = await downloadHTML(t('priorAuth.title'));

    const signaturesRequired = demande?.parents?.length || 1;
    const signed = someoneHasSigned ? 1 : 0;
    await api.demandes.signPriorAuth().then((r) => {});
    console.log(signaturesRequired, signed);
    await getSigningStatus(signed, signaturesRequired);
  };
  const submitFn = (data: any) => {
    savePriorAuth(data);
  };

  const etatsSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.etats,
    i18n.language
  );
  const statutconjugalSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.statutconjugal,
    i18n.language
  );
  const preuveCitSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.preuveCit,
    i18n.language
  );
  const preuvededomicileSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.preuvededomicile,
    i18n.language
  );
  const preuvedetatcivilSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.preuvedetatcivil,
    i18n.language
  );
  const preuveunionconjugaleSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.preuveunionconjugale,
    i18n.language
  );
  const preuvevalidationpiSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.preuvevalidationpi,
    i18n.language
  );

  const statutderesidenceaucanadaSelection = choiceTypeToSelectOptions(
    priorAuthFormTypes?.statutResidenceAuCanada,
    i18n.language
  );

  return (
    <ScreenContainer>
      <div className='sticky top-0 flex justify-between bg-white border-gray-200 py-2 z-10 border-b -mx-4 sm:-mx-6 lg:-mx-8 px-4 sm:px-6 lg:px-8'>
        <BackButton />
        <Button
          label={t('common.forms.save')}
          type={ButtonTypes.submit}
          primary
          compact
        />
      </div>

      <div className='pb-4 exportable-form'>
        <SectionContainer>
          <h1 className='heading1'>{t('priorAuth.title')}</h1>
          <RequestDetails requestData={demande} />
        </SectionContainer>
        <SectionContainer>
          <LinkList title={t('priorAuth.links.title')} links={links} />
        </SectionContainer>
        <SectionContainer>
          <h2 id='instructions' className='heading2'>
            {t('priorAuth.instructions.title')}
          </h2>
          <ReactMarkdown className='mb-10'>
            {t('priorAuth.instructions.paragraph')}
          </ReactMarkdown>
          <Accordion title={t('priorAuth.instructions.accordion1.title')}>
            <ReactMarkdown>
              {t('priorAuth.instructions.accordion1.text')}
            </ReactMarkdown>
          </Accordion>
          <Accordion title={t('priorAuth.instructions.accordion2.title')}>
            <ReactMarkdown>
              {t('priorAuth.instructions.accordion2.steps')}
            </ReactMarkdown>
            <p className='mt-5 italic'>
              {t('priorAuth.instructions.accordion2.italicText')}
            </p>
          </Accordion>
          <Accordion title={t('priorAuth.instructions.accordion3.title')}>
            <ReactMarkdown>
              {t('priorAuth.instructions.accordion3.text')}
            </ReactMarkdown>
          </Accordion>
          <ReactMarkdown className='mt-10 mb-4 whitespace-pre-wrap'>
            {t('priorAuth.instructions.finalText')}
          </ReactMarkdown>
          <ReactMarkdown className='mb-10'>
            {t('priorAuth.instructions.note')}
          </ReactMarkdown>
        </SectionContainer>
        <SectionContainer>
          <h2 className='heading2'>{t('priorAuth.regulation.title')}</h2>
          <ReactMarkdown className='mt-4'>
            {t('priorAuth.regulation.paragraph')}
          </ReactMarkdown>
          <ReactMarkdown className='my-4'>
            {t('priorAuth.regulation.list')}
          </ReactMarkdown>
        </SectionContainer>
        <SectionContainer>
          <h2 id='userId' className='heading2'>
            {t('priorAuth.form.parents.title')}
          </h2>
          <p>{t('priorAuth.form.parents.description')}</p>
          <Form onSubmit={handleSubmit(submitFn)}>
            <fieldset disabled={someoneHasSigned}>
              <Columns numOfCols={2}>
                <div>
                  <h3 className='heading3 mb-6'>
                    {t('priorAuth.parentOfIntent', {
                      number: demande?.parents?.[1] ? '1' : '',
                    })}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredTextField
                      name={'parent1.lastName'}
                      register={register}
                      label={t('priorAuth.form.parents.familyName')}
                      defaultValue={demande?.parents?.[0]?.lastName ?? ''}
                      readonly
                    />
                    <RegisteredTextField
                      name={'parent1.firstName'}
                      register={register}
                      label={t('priorAuth.form.parents.firstName')}
                      defaultValue={demande?.parents?.[0]?.firstName ?? ''}
                      readonly
                    />
                  </Columns>
                  <RegisteredSelect
                    name={'parent1.residence'}
                    register={register}
                    label={t('priorAuth.form.parents.select.label', {
                      name: demande?.parents?.[0].firstName,
                    })}
                    placeholder={t('priorAuth.form.parents.select.placeholder')}
                    options={statutderesidenceaucanadaSelection}
                    errorMessage={errors?.parent1?.residence?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {demande?.parents?.[1] && (
                    <>
                      <h3 className='heading3 mb-6'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h3>
                      <Columns numOfCols={2}>
                        <RegisteredTextField
                          name={'parent2.lastName'}
                          register={register}
                          label={t('priorAuth.form.parents.familyName')}
                          defaultValue={demande?.parents?.[1]?.lastName ?? ''}
                          readonly
                          errorMessage={errors?.parent2?.lastName?.message?.toString()}
                        />
                        <RegisteredTextField
                          name={'parent2.firstName'}
                          register={register}
                          label={t('priorAuth.form.parents.firstName')}
                          readonly
                          errorMessage={errors?.parent2?.firstName?.message?.toString()}
                        />
                      </Columns>
                      <RegisteredSelect
                        name={'parent2.residence'}
                        register={register}
                        label={t('priorAuth.form.parents.select.label', {
                          name: demande?.parents?.[1].firstName,
                        })}
                        options={statutderesidenceaucanadaSelection}
                        placeholder={t('priorAuth.form.parents.select.citizen')}
                        errorMessage={errors?.parent2?.residence?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
              <h2 id='state' className='heading2'>
                {t('priorAuth.form.chosenState.title')}
              </h2>
              <p className='my-5'>{t('priorAuth.form.chosenState.subtitle')}</p>
              <Columns numOfCols={2} className='md:gap-24'>
                <RegisteredSelect
                  name={'chosenState'}
                  register={register}
                  label={t('priorAuth.form.chosenState.select')}
                  placeholder={t('priorAuth.form.chosenState.placeholder')}
                  options={etatsSelection}
                  errorMessage={errors?.chosenState?.message?.toString()}
                  required
                />
              </Columns>
              <h2 id='attachDocs' className='heading2'>
                {t('priorAuth.form.documentsAttach.heading')}
              </h2>
              <p>{t('priorAuth.form.documentsAttach.paragraph')}</p>
              <h3 className='heading3'>
                {t('priorAuth.form.documentsAttach.idProof.heading', {
                  number: 2,
                })}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {demande?.parents?.[1] && (
                    <h4 className='heading4 mb-6'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredSelect
                    name='parent1.idProof1.docType'
                    register={register}
                    label={t('priorAuth.form.documentsAttach.idProof.label', {
                      number: 1,
                    })}
                    options={preuvevalidationpiSelection}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.idProof1?.docType?.message?.toString()}
                    required
                  />
                  <RegisteredDateField
                    name='parent1.idProof1.expiration'
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.idProof.expiration'
                    )}
                    errorMessage={errors?.parent1?.idProof1?.expiration?.message?.toString()}
                  />
                  <div className='mt-16'>
                    <RegisteredSelect
                      name='parent1.idProof2.docType'
                      register={register}
                      label={t('priorAuth.form.documentsAttach.idProof.label', {
                        number: 2,
                      })}
                      options={preuvevalidationpiSelection}
                      placeholder={t('common.forms.selectPlaceholder')}
                      errorMessage={errors?.parent1?.idProof2?.docType?.message?.toString()}
                      required
                    />
                    <RegisteredDateField
                      name='parent1.idProof2.expiration'
                      register={register}
                      label={t(
                        'priorAuth.form.documentsAttach.idProof.expiration'
                      )}
                      errorMessage={errors?.parent1?.idProof2?.expiration?.message?.toString()}
                    />
                  </div>
                </div>
                <div>
                  {demande?.parents?.[1] && (
                    <>
                      {demande?.parents?.[1] && (
                        <h4 className='heading4 mb-6'>
                          {t('priorAuth.parentOfIntent', { number: 2 })}
                        </h4>
                      )}
                      <RegisteredSelect
                        name={'parent2.idProof1.docType'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.idProof.label',
                          {
                            number: 1,
                          }
                        )}
                        options={preuvevalidationpiSelection}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.parent2?.idProof1?.docType?.message?.toString()}
                        required
                      />
                      <RegisteredDateField
                        name={'parent2.idProof1.expiration'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.idProof.expiration'
                        )}
                        errorMessage={errors?.parent2?.idProof1?.expiration?.message?.toString()}
                      />
                      <div className='mt-16'>
                        <RegisteredSelect
                          name={'parent2.idProof2.docType'}
                          register={register}
                          label={t(
                            'priorAuth.form.documentsAttach.idProof.label',
                            {
                              number: 2,
                            }
                          )}
                          options={preuvevalidationpiSelection}
                          placeholder={t('common.forms.selectPlaceholder')}
                          errorMessage={errors?.parent2?.idProof2?.docType?.message?.toString()}
                          required
                        />
                        <RegisteredDateField
                          name={'parent2.idProof2.expiration'}
                          register={register}
                          label={t(
                            'priorAuth.form.documentsAttach.idProof.expiration'
                          )}
                          errorMessage={errors?.parent2?.idProof2?.expiration?.message?.toString()}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Columns>
              <h3 className='heading3'>
                {t('priorAuth.form.documentsAttach.proofOfDomicile.heading')}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {demande?.parents?.[1] && (
                    <h4 className='heading4 mb-6'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredSelect
                    name={'parent1.residenceProofType1'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.proofOfDomicile.label',
                      {
                        number: 1,
                      }
                    )}
                    options={preuvededomicileSelection}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.residenceProofType1?.message?.toString()}
                    required
                  />
                  <RegisteredSelect
                    name={'parent1.residenceProofType2'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.proofOfDomicile.label',
                      {
                        number: 2,
                      }
                    )}
                    options={preuvededomicileSelection}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.residenceProofType2?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {demande?.parents?.[1] && (
                    <>
                      <h4 className='heading4 mb-6'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h4>
                      <RegisteredSelect
                        name={'parent2.residenceProofType1'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.proofOfDomicile.label',
                          {
                            number: 1,
                          }
                        )}
                        options={preuvededomicileSelection}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.parent2?.residenceProofType1?.message?.toString()}
                        required
                      />
                      <RegisteredSelect
                        name={'parent2.residenceProofType2'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.proofOfDomicile.label',
                          {
                            number: 2,
                          }
                        )}
                        options={preuvededomicileSelection}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.parent2?.residenceProofType2?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
              <ReactMarkdown>
                {t('priorAuth.form.documentsAttach.proofOfDomicile.note')}
              </ReactMarkdown>
              <h3 className='heading3'>
                {t(
                  'priorAuth.form.documentsAttach.canadianCitizenship.heading'
                )}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {demande?.parents?.[1] && (
                    <h4 className='heading4 mb-6'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredSelect
                    name={'parent1.citizenshipProof.docType'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.ifProof'
                    )}
                    options={preuveCitSelection}
                    placeholder={t('common.forms.selectPlaceholder')}
                    errorMessage={errors?.parent1?.citizenshipProof?.docType?.message?.toString()}
                    required
                  />
                  <RegisteredDateField
                    name={'parent1.citizenshipProof.expiration'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.canadianCitizenship.proofType.expiration'
                    )}
                    errorMessage={errors?.parent1?.citizenshipProof?.expiration?.message?.toString()}
                  />
                </div>
                {demande?.parents?.[1] ? (
                  <div>
                    <h4 className='heading4 mb-6'>
                      {t('priorAuth.parentOfIntent', { number: 2 })}
                    </h4>
                    <RegisteredSelect
                      name={'parent2.citizenshipProof.docType'}
                      register={register}
                      label={t(
                        'priorAuth.form.documentsAttach.canadianCitizenship.proofType.ifProof'
                      )}
                      options={preuveCitSelection}
                      placeholder={t('common.forms.selectPlaceholder')}
                      errorMessage={errors?.parent2?.citizenshipProof?.docType?.message?.toString()}
                      required
                    />
                    <RegisteredDateField
                      name={'parent2.citizenshipProof.expiration'}
                      register={register}
                      label={t(
                        'priorAuth.form.documentsAttach.canadianCitizenship.proofType.expiration'
                      )}
                      errorMessage={errors?.parent2?.citizenshipProof?.expiration?.message?.toString()}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Columns>

              <h3 className='heading3 mb-5 w-10/12'>
                {t('priorAuth.form.documentsAttach.certification.heading')}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {demande?.parents?.[1] && (
                    <h4 className='heading4 mb-6'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredDateField
                    name={'parent1.certOfAttendanceDate'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.certification.date'
                    )}
                    errorMessage={errors?.parent1?.certOfAttendanceDate?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {demande?.parents?.[1] && (
                    <>
                      <h4 className='heading4 mb-6'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h4>
                      <RegisteredDateField
                        name={'parent2.certOfAttendanceDate'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.certification.date'
                        )}
                        errorMessage={errors?.parent2?.certOfAttendanceDate?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>

              <h3 className='heading3 mb-5'>
                {t('priorAuth.form.documentsAttach.criminalRecord.heading')}
              </h3>
              <Columns numOfCols={2}>
                <div>
                  {demande?.parents?.[1] && (
                    <h4 className='heading4 mb-6'>
                      {t('priorAuth.parentOfIntent', { number: 1 })}
                    </h4>
                  )}
                  <RegisteredDateField
                    name={'parent1.criminalRecordCheckDate'}
                    register={register}
                    label={t(
                      'priorAuth.form.documentsAttach.criminalRecord.date'
                    )}
                    errorMessage={errors?.parent1?.criminalRecordCheckDate?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {demande?.parents?.[1] && (
                    <>
                      <h4 className='heading4 mb-6'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h4>
                      <RegisteredDateField
                        name={'parent2.criminalRecordCheckDate'}
                        register={register}
                        label={t(
                          'priorAuth.form.documentsAttach.criminalRecord.date'
                        )}
                        errorMessage={errors?.parent2?.criminalRecordCheckDate?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
              <ReactMarkdown>
                {t('priorAuth.form.documentsAttach.criminalRecord.note')}
              </ReactMarkdown>

              {demande?.parents?.[1] && (
                <>
                  <h2 className='heading2' id='maritalStatus'>
                    {t('priorAuth.form.maritalStatus.title')}
                  </h2>
                  <p className='my-5'>
                    {t('priorAuth.form.maritalStatus.subtitle')}
                  </p>
                  <>
                    <RegisteredRadioGroup
                      name={'marriageType'}
                      register={register}
                      options={statutconjugalSelection}
                      label={t('priorAuth.form.maritalStatus.unionType.title')}
                      errorMessage={errors?.marriageType?.message?.toString()}
                      required
                    />
                    {(marriageType === 'marriage' ||
                      marriageType === 'civilUnion') && (
                      <RegisteredSelect
                        name={'marriageDocument'}
                        register={register}
                        label={t('priorAuth.form.maritalStatus.document.title')}
                        options={preuveunionconjugaleSelection}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors?.marriageDocument?.message?.toString()}
                        required
                      />
                    )}
                  </>
                </>
              )}
              <h2 className='heading2' id='declaration'>
                {t('priorAuth.form.declaration.title')}
              </h2>
              <Columns numOfCols={2} className='md:gap-24'>
                <div>
                  <RegisteredDateField
                    name={'dateOfDeclaration'}
                    register={register}
                    label={t('priorAuth.form.declaration.date')}
                    errorMessage={errors?.dateOfDeclaration?.message?.toString()}
                    required
                  />
                  <RegisteredTextField
                    name={'declarationNumber'}
                    register={register}
                    label={t('priorAuth.form.declaration.number')}
                    errorMessage={errors?.declarationNumber?.message?.toString()}
                    required
                  />
                </div>
              </Columns>
              <h2 className='heading2' id='consent'>
                {t('priorAuth.form.consent.title')}
              </h2>
              <ReactMarkdown className='my-5 whitespace-pre-wrap'>
                {t('priorAuth.form.consent.paragraph')}
              </ReactMarkdown>
              <Columns numOfCols={2} className='md:gap-24'>
                <div>
                  {demande?.parents?.[1] && (
                    <h3 className='heading3'>
                      {t('priorAuth.parentOfIntent', {
                        number: !demande?.isCouple ? '' : 1,
                      })}
                    </h3>
                  )}
                  <RegisteredRadioGroup
                    name={'parent1.consent'}
                    register={register}
                    options={consentSelection}
                    label={t('priorAuth.form.consent.parent.label')}
                    errorMessage={errors?.parent1?.consent?.message?.toString()}
                    required
                  />
                </div>
                <div>
                  {demande?.parents?.[1] && (
                    <>
                      <h3 className='heading3'>
                        {t('priorAuth.parentOfIntent', { number: 2 })}
                      </h3>
                      <RegisteredRadioGroup
                        name={'parent2.consent'}
                        register={register}
                        options={consentSelection}
                        label={t('priorAuth.form.consent.parent.label')}
                        errorMessage={errors?.parent2?.consent?.message?.toString()}
                        required
                      />
                    </>
                  )}
                </div>
              </Columns>
            </fieldset>
            <h3 className='heading3 mt-20'>
              {t('changeRequest.signatureForm')}
            </h3>

            <Columns numOfCols={2}>
              <div className='flex flex-col'>
                <h4 className={'heading4 mb-2 space-x-4'}>
                  <span>
                    {selfParent?.firstName} {selfParent?.lastName}
                  </span>
                  {selfParentHasSigned ? (
                    <Pill
                      type={'success'}
                      text={t('common.signature.signedLabel')}
                    />
                  ) : (
                    <Pill
                      type={'warning'}
                      text={t('common.signature.toSignLabel')}
                    />
                  )}
                </h4>

                {!selfParentHasSigned ? (
                  <>
                    <p className={'mb-8'}>
                      {t('common.signature.clickToSign')}
                    </p>
                    <Button
                      onClick={sign}
                      primary
                      label={t('changeRequest.button')}
                      className='inline mr-auto'
                    />
                  </>
                ) : (
                  <>
                    <p className={'mb-8'}>
                      {t('common.signature.signed', {
                        name: `${selfParent?.firstName} ${selfParent?.lastName}`,
                        time: getFormattedDateTime(
                          selfParentHasSigned,
                          i18n.language
                        ),
                      })}
                    </p>
                  </>
                )}
              </div>
              {otherParent && (
                <>
                  <div className='flex flex-col'>
                    <h4 className={'heading4 mb-2 space-x-4'}>
                      <span>
                        {otherParent?.firstName} {otherParent?.lastName}
                      </span>
                      {otherParentHasSigned ? (
                        <Pill
                          type={'success'}
                          text={t('common.signature.signedLabel')}
                        />
                      ) : (
                        <Pill
                          type={'warning'}
                          text={t('common.signature.toSignLabel')}
                        />
                      )}
                    </h4>
                    {otherParentHasSigned ? (
                      <p className={'mb-8'}>
                        {t('common.signature.signed', {
                          name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                          time: getFormattedDateTime(
                            otherParentHasSigned,
                            i18n.language
                          ),
                        })}
                      </p>
                    ) : (
                      <p className={'mb-8'}>
                        {t('common.signature.mustSign', {
                          name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                        })}
                      </p>
                    )}
                  </div>
                </>
              )}
            </Columns>
          </Form>
        </SectionContainer>
      </div>
    </ScreenContainer>
  );
};

export default PriorAuth;
