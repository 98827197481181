import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import LinkList from '../components/LinkList';
import Accordion from '../components/containers/Accordion';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import RegisteredCheckboxGroup from '../forms/registeredFields/RegisteredCheckboxGroup';
import RegisteredDateField from '../forms/registeredFields/RegisteredDateField';
import RegisteredRadioGroup from '../forms/registeredFields/RegisteredRadioGroup';
import RegisteredSelect from '../forms/registeredFields/RegisteredSelect';
import RegisteredTextArea from '../forms/registeredFields/RegisteredTextArea';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import i18n from '../i18n';
import {
  getGametesOptions,
  getParentReproductiveMaterialUsedOptions,
  parentAuthDefaultValues,
  parentAuthSchema,
} from '../utils/parentAuthForm.utils';
import {
  DemandeGPAClientPayload,
  ParentAuthFormTypes,
} from '../api/methods/demandes';
import { useApi } from '../api/APIContext';
import RequestDetails from '../components/RequestDetails';
import choiceTypeToSelectOptions from '../lib/choiceTypeToSelectOptions';
import BackButton from '../components/BackButton';
import { SignatureStatus } from '../utils/types';
import { downloadHTML } from '../utils/pageToPdf';
import Pill from '../components/Pill';
import { getFormattedDateTime } from '../lib/dateformat';

const ParentAuth = () => {
  const { t } = useTranslation();
  const api = useApi();
  const [demande, setDemande] = useState<DemandeGPAClientPayload>();
  const [parentAuthFormTypes, setParentAuthFormTypes] =
    useState<ParentAuthFormTypes>();
  const [signingStatus, setSigningStatus] = useState<SignatureStatus>();

  const selfParent = demande?.parents?.find((p) => p.isSelf);
  const otherParent =
    !!demande?.parents?.[1] && demande?.parents?.find((p) => !p.isSelf);
  const selfPINumber = (demande?.parents?.findIndex((p) => p.isSelf) ?? -1) + 1;
  const selfParentHasSigned =
    selfPINumber == 1 ? signingStatus?.signed_pi1 : signingStatus?.signed_pi2;
  const otherPINumber =
    (demande?.parents?.findIndex((p) => !p.isSelf) ?? -1) + 1;
  const otherParentHasSigned =
    otherPINumber > 0
      ? otherPINumber == 1
        ? signingStatus?.signed_pi1
        : signingStatus?.signed_pi2
      : undefined;

  const someoneHasSigned = !!selfParentHasSigned || !!otherParentHasSigned;
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    // resolver: zodResolver(parentAuthSchema(i18n.language)),
    defaultValues: parentAuthDefaultValues,
    shouldUnregister: true,
  });

  useEffect(() => {
    api.demandes.getActivedemande().then(setDemande);
    api.demandes.getParentAuthFormTypes().then(setParentAuthFormTypes);
    api.demandes
      .getParentAuth()
      .then(reset)
      .then(() => getSigningStatus());
  }, []);

  const sign = async () => {
    const html = await downloadHTML(t('parentAuth.title'));

    const signaturesRequired = demande?.parents?.length || 1;
    const signed = someoneHasSigned ? 1 : 0;
    await api.demandes.signParentAuth().then((r) => {});
    console.log(signaturesRequired, signed);

    await getSigningStatus(signed, signaturesRequired);
  };

  const getSigningStatus = async (
    previouslySignedNumber?: number,
    requiredNumber?: number
  ) => {
    const signing = await api.demandes.getParentAuthSigningStatus();
    setSigningStatus(signing);
    let numberSigned = 0;
    numberSigned += signing?.signed_pi1 ? 1 : 0;
    numberSigned += signing?.signed_pi2 ? 1 : 0;

    if (
      previouslySignedNumber &&
      requiredNumber &&
      previouslySignedNumber != requiredNumber &&
      numberSigned == requiredNumber
    ) {
      //wait 1s as a promise
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const html = await downloadHTML(t('parentAuth.title'));
      if (!html) return;
      await api.files.uploadFile(
        demande?.id || '',
        demande?.stage?.step || 2,
        t('parentAuth.title') + '.html',
        html
      );
    }
  };

  const etatsSelection = choiceTypeToSelectOptions(
    parentAuthFormTypes?.etats,
    i18n.language
  );
  const preuvedecitoyenneteouresidencepermSelection = choiceTypeToSelectOptions(
    parentAuthFormTypes?.preuvedecitoyenneteouresidenceperm,
    i18n.language
  );

  const centredeprocreationassistelettreSelection = choiceTypeToSelectOptions(
    parentAuthFormTypes?.centredeprocreationassistelettre,
    i18n.language
  );

  const preuveidentiteSelection = choiceTypeToSelectOptions(
    parentAuthFormTypes?.preuvevalidationpi,
    i18n.language
  );

  const preuvedomicileSelection = choiceTypeToSelectOptions(
    parentAuthFormTypes?.preuvedomicile,
    i18n.language
  );

  const submitFn = (data: any) => {
    api.demandes.saveParentAuth(data);
  };

  const links: { label: string; href: string }[] = t('parentAuth.links.items', {
    returnObjects: true,
  });

  const surrogateMaterialUsed = watch('surrogateMaterialUsed');
  const reproductiveMaterialParent1 = watch('reproductiveMaterial.parent1');
  const reproductiveMaterialParent2 = watch('reproductiveMaterial.parent2');
  const gametesOptions = getGametesOptions(
    surrogateMaterialUsed === 'yes' ||
      reproductiveMaterialParent1 === 'ovocyte' ||
      reproductiveMaterialParent2 === 'ovocyte',
    reproductiveMaterialParent1 === 'sperm' ||
      reproductiveMaterialParent2 === 'sperm',
    t
  );

  const parentReproductiveMaterialUsedOptions =
    getParentReproductiveMaterialUsedOptions(
      t,
      watch().surrogateMaterialUsed === 'yes'
    );

  const hasChanges = watch('changes.hasChanges');
  const changes = watch('changes.changes');
  const [parent1, parent2] = watch([
    'reproductiveMaterial.parent1',
    'reproductiveMaterial.parent2',
  ]);

  useEffect(() => {
    const getStatus = (hasChanges: string, changes: string[]) => {
      switch (true) {
        case hasChanges === 'no':
          return 'noChanges';
        case hasChanges === 'yes' &&
          changes?.includes('maritalStatus') &&
          changes?.includes('residence'):
          return 'hasMaritalAndResidenceChanges';
        case hasChanges === 'yes' && changes?.includes('maritalStatus'):
          return 'hasMaritalStatusChanges';
        case hasChanges === 'yes' && changes?.includes('residence'):
          return 'hasResidenceChanges';
        case hasChanges === 'yes':
          return 'undeterminedChanges';
        default:
          return '';
      }
    };
    const status = getStatus(hasChanges, changes);

    setValue('changes.status', status);
  }, [hasChanges, changes, setValue]);

  useEffect(() => {
    const materials = [parent1, parent2];

    if (materials.includes('ovocyte') && materials.includes('sperm')) {
      return setValue('reproductiveMaterial.needsDonationMaterial', 'no');
    }

    return setValue('reproductiveMaterial.needsDonationMaterial', 'yes');
  }, [parent1, parent2, setValue]);

  return (
    <ScreenContainer>
      <Form onSubmit={handleSubmit(submitFn)}>
        <div className='sticky top-0 flex justify-between bg-white border-gray-200 py-2 z-10 border-b -mx-4 sm:-mx-6 lg:-mx-8 px-4 sm:px-6 lg:px-8'>
          <BackButton />
          <Button
            label={t('common.forms.save')}
            type={ButtonTypes.submit}
            primary
            compact
          />
        </div>
        <div className={'exportable-form'}>
          <SectionContainer>
            <h1 className='heading1'>{t('parentAuth.title')}</h1>
            <RequestDetails requestData={demande} />
          </SectionContainer>
          <SectionContainer>
            <LinkList title={t('parentAuth.links.title')} links={links} />
          </SectionContainer>
          <SectionContainer>
            <h2 id='instructions' className='heading2'>
              {t('parentAuth.instructions.heading')}
            </h2>
            <ReactMarkdown className='mb-10'>
              {t('parentAuth.instructions.paragraph')}
            </ReactMarkdown>
            <Accordion title={t('parentAuth.instructions.accordion1.heading')}>
              <ReactMarkdown>
                {t('parentAuth.instructions.accordion1.paragraph')}
              </ReactMarkdown>
              <ReactMarkdown className='mt-5'>
                {t('parentAuth.instructions.accordion1.note')}
              </ReactMarkdown>
            </Accordion>
            <Accordion title={t('parentAuth.instructions.accordion2.heading')}>
              <ReactMarkdown>
                {t('parentAuth.instructions.accordion2.paragraph')}
              </ReactMarkdown>
            </Accordion>
            <ReactMarkdown className='mt-5 whitespace-pre-wrap'>
              {t('parentAuth.instructions.accordion2.note')}
            </ReactMarkdown>
          </SectionContainer>
          <SectionContainer>
            <h2 id='regulations' className='heading2'>
              {t('parentAuth.regulations.heading')}
            </h2>
            <ReactMarkdown className='whitespace-pre-wrap'>
              {t('parentAuth.regulations.paragraph')}
            </ReactMarkdown>
            <ReactMarkdown>{t('parentAuth.regulations.list')}</ReactMarkdown>
            <ReactMarkdown className='my-5'>
              {t('parentAuth.regulations.disclaimer')}
            </ReactMarkdown>
          </SectionContainer>
          <SectionContainer>
            <h2 className='heading2' id='parentalChanges'>
              {t('parentAuth.parentalChanges.heading')}
            </h2>
            <p>{t('parentAuth.parentalChanges.paragraph')}</p>
          </SectionContainer>
          <SectionContainer>
            <h3 className='heading3'>
              {t('parentAuth.changeForm.parentChange.heading')}
            </h3>

            <RegisteredRadioGroup
              required
              name={'changes.hasChanges'}
              register={register}
              label={t('parentAuth.changeForm.parentChange.selectHeading')}
              options={[
                { value: 'yes', label: t('common.yes') },
                { value: 'no', label: t('common.no') },
              ]}
              errorMessage={errors.changes?.hasChanges?.message}
            />
            {watch('changes.hasChanges') === 'yes' && (
              <>
                <RegisteredCheckboxGroup
                  label={t(
                    'parentAuth.changeForm.parentChange.checkbox.heading'
                  )}
                  required={true}
                  errorMessage={errors.changes?.changes?.message}
                  name='changes.changes'
                  register={register}
                  options={[
                    {
                      label: t(
                        'parentAuth.changeForm.parentChange.checkbox.maritalStatus.heading'
                      ),
                      name: 'maritalStatus',
                      description: t(
                        'parentAuth.changeForm.parentChange.checkbox.maritalStatus.sub'
                      ),
                    },
                    {
                      label: t(
                        'parentAuth.changeForm.parentChange.checkbox.residence.heading'
                      ),
                      name: 'residence',
                      description: t(
                        'parentAuth.changeForm.parentChange.checkbox.residence.sub'
                      ),
                    },
                    {
                      label: t(
                        'parentAuth.changeForm.parentChange.checkbox.other'
                      ),
                      name: 'other',
                    },
                  ]}
                />
                {watch('changes.changes')?.includes('other') && (
                  <p>
                    {t('parentAuth.changeForm.parentChange.checkbox.paragraph')}
                  </p>
                )}

                {watch('changes.changes')?.includes('maritalStatus') ||
                  (watch('changes.changes')?.includes('residence') && (
                    <h3 className='heading3'>
                      {t('parentAuth.changeForm.parentChange.docs.heading')}
                    </h3>
                  ))}
                {watch('changes.changes')?.includes('maritalStatus') && (
                  <>
                    <RegisteredSelect
                      required
                      name='changes.changeAttachedDoc'
                      register={register}
                      label={t(
                        'parentAuth.changeForm.parentChange.docs.marriageStatusSelect.title'
                      )}
                      errorMessage={errors.changes?.changeAttachedDoc?.message}
                      options={[
                        {
                          label: t(
                            'parentAuth.changeForm.parentChange.docs.marriageStatusSelect.options.marriage'
                          ),
                          value: 'marriage',
                        },
                        {
                          label: t(
                            'parentAuth.changeForm.parentChange.docs.marriageStatusSelect.options.civilUnion'
                          ),
                          value: 'civilUnion',
                        },
                      ]}
                      placeholder={t('common.forms.selectPlaceholder')}
                    />
                  </>
                )}
                {watch('changes.changes')?.includes('residence') && (
                  <>
                    <RegisteredSelect
                      required
                      name={'changes.residenceChangeDoc'}
                      register={register}
                      errorMessage={errors.changes?.residenceChangeDoc?.message}
                      label={t(
                        'parentAuth.changeForm.parentChange.docs.residenceSelect.title'
                      )}
                      options={preuvedecitoyenneteouresidencepermSelection}
                      placeholder={t('common.forms.selectPlaceholder')}
                    />
                    <RegisteredTextArea
                      required
                      name={'changes.parentChangeExplanation'}
                      errorMessage={
                        errors.changes?.parentChangeExplanation?.message
                      }
                      register={register}
                      title={t(
                        'parentAuth.changeForm.parentChange.docs.residenceSelect.explanation.title'
                      )}
                    />
                  </>
                )}
              </>
            )}

            <h2 className='heading2' id='surrogate'>
              {t('parentAuth.changeForm.surrogate.heading')}
            </h2>
            <h3 className='heading3'>
              {t('parentAuth.changeForm.surrogate.subheading')}
            </h3>
            <Columns numOfCols={2}>
              <RegisteredTextField
                errorMessage={errors.surrogate?.lastName?.message}
                required
                name={'surrogate.lastName'}
                register={register}
                label={t('parentAuth.changeForm.surrogate.id.familyName')}
              />
              <RegisteredTextField
                required
                errorMessage={errors.surrogate?.firstName?.message}
                name={'surrogate.firstName'}
                register={register}
                label={t('parentAuth.changeForm.surrogate.id.firstName')}
              />
            </Columns>
            <Columns numOfCols={2}>
              <RegisteredDateField
                required
                errorMessage={errors.surrogate?.dob?.message}
                name={'surrogate.dob'}
                register={register}
                label={t('parentAuth.changeForm.surrogate.id.birthday')}
              />
            </Columns>
            <h3 className='heading3'>
              {t('parentAuth.changeForm.surrogate.contact.heading')}
            </h3>
            <Columns numOfCols={3}>
              <RegisteredTextField
                required
                errorMessage={errors.surrogate?.address?.message}
                name={'surrogate.address'}
                register={register}
                label={t('parentAuth.changeForm.surrogate.contact.address')}
              />
              <RegisteredTextField
                required
                name={'surrogate.city'}
                register={register}
                label={t('parentAuth.changeForm.surrogate.contact.city')}
                errorMessage={errors.surrogate?.city?.message}
              />
              <RegisteredSelect
                required
                name={'surrogate.state'}
                register={register}
                label={t('parentAuth.changeForm.surrogate.contact.state')}
                options={etatsSelection}
                errorMessage={errors.surrogate?.state?.message}
                placeholder={t('common.forms.selectPlaceholder')}
              />
            </Columns>
            <Columns numOfCols={3}>
              <RegisteredTextField
                required
                name={'surrogate.postalCode'}
                errorMessage={errors.surrogate?.postalCode?.message}
                register={register}
                label={t('parentAuth.changeForm.surrogate.contact.postalCode')}
              />
              <RegisteredTextField
                required
                name={'surrogate.telephone'}
                register={register}
                label={t('parentAuth.changeForm.surrogate.contact.phone')}
                errorMessage={errors.surrogate?.telephone?.message}
              />
              <RegisteredTextField
                required
                name={'surrogate.email'}
                register={register}
                errorMessage={errors.surrogate?.email?.message}
                label={t('parentAuth.changeForm.surrogate.contact.email')}
              />
            </Columns>

            <h3 className='heading3'>
              {t('parentAuth.changeForm.surrogate.attachDocs.heading')}
            </h3>
            <Columns numOfCols={2}>
              <div>
                <RegisteredSelect
                  required
                  name={'documents.idDocType'}
                  register={register}
                  label={t(
                    'parentAuth.changeForm.surrogate.attachDocs.idDocument.type'
                  )}
                  options={preuveidentiteSelection}
                  errorMessage={errors.documents?.idDocType?.message}
                  placeholder={t('common.forms.selectPlaceholder')}
                />
                <RegisteredDateField
                  name={'documents.idDocExpiration'}
                  register={register}
                  label={t(
                    'parentAuth.changeForm.surrogate.attachDocs.idDocument.expiration'
                  )}
                  errorMessage={errors.documents?.idDocExpiration?.message}
                />
                <p className='-mt-8'>
                  {t(
                    'parentAuth.changeForm.surrogate.attachDocs.idDocument.miniText'
                  )}
                </p>
              </div>
              <div>
                <RegisteredSelect
                  required
                  name={'documents.addressDocType'}
                  register={register}
                  label={t(
                    'parentAuth.changeForm.surrogate.attachDocs.addressDoc.type'
                  )}
                  options={preuvedomicileSelection}
                  placeholder={t('common.forms.selectPlaceholder')}
                  errorMessage={errors.documents?.addressDocType?.message}
                />
                <RegisteredDateField
                  required
                  name={'documents.addressDocExpiration'}
                  register={register}
                  label={t(
                    'parentAuth.changeForm.surrogate.attachDocs.addressDoc.expiration'
                  )}
                  errorMessage={errors.documents?.addressDocExpiration?.message}
                />
                <p className='-mt-8'>
                  {t(
                    'parentAuth.changeForm.surrogate.attachDocs.addressDoc.miniText'
                  )}
                </p>
              </div>
            </Columns>

            <h3 className='heading3'>
              {t('parentAuth.changeForm.surrogate.confirmAltruism.heading')}
            </h3>
            <RegisteredCheckbox
              required
              name={'altruism'}
              value={'altruism'}
              register={register}
              label={
                demande?.isCouple
                  ? t(
                      'parentAuth.changeForm.surrogate.confirmAltruism.textPlural'
                    )
                  : t(
                      'parentAuth.changeForm.surrogate.confirmAltruism.textSingular'
                    )
              }
              errorMessage={errors.altruism?.message}
            />

            <h3 className='heading3'>
              {t('parentAuth.changeForm.surrogate.general.heading')}
            </h3>
            <Columns numOfCols={2}>
              <RegisteredTextField
                required
                name={'ethnicOrigin'}
                register={register}
                label={t(
                  'parentAuth.changeForm.surrogate.general.ethnicOrigin'
                )}
                errorMessage={errors.ethnicOrigin?.message}
              />
              <RegisteredTextField
                required
                name={'education'}
                errorMessage={errors.education?.message}
                register={register}
                label={t('parentAuth.changeForm.surrogate.general.education')}
              />
            </Columns>
            <RegisteredTextArea
              required
              name={'diplomas'}
              register={register}
              title={t('parentAuth.changeForm.surrogate.general.diplomas')}
              errorMessage={errors.diplomas?.message}
            />
            <RegisteredTextField
              required
              name={'profession'}
              register={register}
              label={t('parentAuth.changeForm.surrogate.general.profession')}
              errorMessage={errors.profession?.message}
            />
            <RegisteredRadioGroup
              required
              name={'surrogateRelationship.maritalStatus'}
              register={register}
              errorMessage={
                errors.surrogateRelationship?.maritalStatus?.message
              }
              options={[
                {
                  label: t(
                    'parentAuth.changeForm.surrogate.maritalStatus.single'
                  ),
                  value: 'single',
                },
                {
                  label: t(
                    'parentAuth.changeForm.surrogate.maritalStatus.civilUnion'
                  ),
                  value: 'civilUnion',
                },
                {
                  label: t(
                    'parentAuth.changeForm.surrogate.maritalStatus.commonLaw'
                  ),
                  value: 'commonLaw',
                },
                {
                  label: t(
                    'parentAuth.changeForm.surrogate.maritalStatus.married'
                  ),
                  value: 'married',
                },
              ]}
              label={t('parentAuth.changeForm.surrogate.maritalStatus.heading')}
            />

            <h3 className='heading3'>
              {t(
                'parentAuth.changeForm.surrogate.physicalCharacteristics.heading'
              )}
            </h3>
            <Columns numOfCols={2}>
              <RegisteredTextField
                required
                name={'height'}
                register={register}
                label={t(
                  'parentAuth.changeForm.surrogate.physicalCharacteristics.size'
                )}
                errorMessage={errors.height?.message}
              />
              <RegisteredTextField
                required
                name={'eyeColour'}
                register={register}
                label={t(
                  'parentAuth.changeForm.surrogate.physicalCharacteristics.eyeColour'
                )}
                errorMessage={errors.eyeColour?.message}
              />
            </Columns>
            <Columns numOfCols={2}>
              <RegisteredTextField
                required
                name={'skinColour'}
                register={register}
                label={t(
                  'parentAuth.changeForm.surrogate.physicalCharacteristics.skinColour'
                )}
                errorMessage={errors.skinColour?.message}
              />
              <RegisteredTextField
                required
                name={'hairColour'}
                register={register}
                label={t(
                  'parentAuth.changeForm.surrogate.physicalCharacteristics.hair'
                )}
                errorMessage={errors.hairColour?.message}
              />
            </Columns>

            <h3 className='heading3'>
              {t('parentAuth.changeForm.surrogate.otherInformation.heading')}
            </h3>
            <RegisteredTextArea
              required
              name={'personalityTraits'}
              register={register}
              title={t(
                'parentAuth.changeForm.surrogate.otherInformation.personalityTraits'
              )}
              errorMessage={errors.personalityTraits?.message}
            />
            <RegisteredTextArea
              name={'motivations'}
              register={register}
              title={t(
                'parentAuth.changeForm.surrogate.otherInformation.motivations'
              )}
              errorMessage={errors.motivations?.message}
            />

            {watch().surrogateRelationship?.maritalStatus !== '' &&
              watch().surrogateRelationship?.maritalStatus !== 'single' && (
                <>
                  <h2 className='heading2' id='surrogateSpouse'>
                    {t('parentAuth.changeForm.spouseOfSurrogate.heading')}
                  </h2>
                  <p>
                    {t('parentAuth.changeForm.spouseOfSurrogate.paragraph')}
                  </p>
                  <h3 className='heading3'>
                    {t('parentAuth.changeForm.spouseOfSurrogate.id.heading')}
                  </h3>
                  <Columns numOfCols={2}>
                    <RegisteredTextField
                      name={'surrogateRelationship.spouseFamilyName'}
                      register={register}
                      label={t(
                        'parentAuth.changeForm.spouseOfSurrogate.id.familyName'
                      )}
                      errorMessage={
                        errors.surrogateRelationship?.spouseFamilyName?.message
                      }
                    />
                    <RegisteredTextField
                      name={'surrogateRelationship.spouseName'}
                      errorMessage={
                        errors.surrogateRelationship?.spouseName?.message
                      }
                      register={register}
                      label={t(
                        'parentAuth.changeForm.spouseOfSurrogate.id.firstName'
                      )}
                    />
                  </Columns>
                </>
              )}
            <h2 className='heading2' id='reproductiveMaterial'>
              {t('parentAuth.changeForm.reproductiveMaterialUsed.heading')}
            </h2>
            <h3 className='heading3'>
              {t(
                'parentAuth.changeForm.reproductiveMaterialUsed.geneticLink.heading'
              )}
            </h3>
            <RegisteredRadioGroup
              required
              name={'surrogateMaterialUsed'}
              register={register}
              options={[
                { value: 'yes', label: t('common.yes') },
                { value: 'no', label: t('common.no') },
              ]}
              errorMessage={errors.surrogateMaterialUsed?.message}
              label={t(
                'parentAuth.changeForm.reproductiveMaterialUsed.geneticLink.radio'
              )}
            />
            {watch().surrogateMaterialUsed && (
              <p className='italic'>
                {t(
                  'parentAuth.changeForm.reproductiveMaterialUsed.geneticLink.italics'
                )}
              </p>
            )}
            <h3 className='heading3'>
              {t(
                'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.heading'
              )}
            </h3>
            <h4 className='heading4'>
              {t(
                'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.subheading'
              )}
            </h4>
            {!true && (
              <ReactMarkdown className='my-5'>
                {t(
                  'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.reminder'
                )}
              </ReactMarkdown>
            )}

            <Columns numOfCols={2}>
              <RegisteredRadioGroup
                required
                errorMessage={errors.reproductiveMaterial?.parent1?.message}
                name={'reproductiveMaterial.parent1'}
                register={register}
                options={parentReproductiveMaterialUsedOptions.filter((e) =>
                  watch().reproductiveMaterial?.parent2 === 'ovocyte'
                    ? e.value !== 'ovocyte'
                    : true
                )}
                label={t(
                  'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.heading',
                  { parentNumber: '1' }
                )}
              />
              <div>
                {demande?.isCouple && (
                  <RegisteredRadioGroup
                    required
                    name={'reproductiveMaterial.parent2'}
                    errorMessage={errors.reproductiveMaterial?.parent2?.message}
                    register={register}
                    options={parentReproductiveMaterialUsedOptions.filter(
                      (e) =>
                        watch().reproductiveMaterial?.parent1 === 'ovocyte'
                          ? e.value !== 'ovocyte'
                          : true
                    )}
                    label={t(
                      'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.parent.heading',
                      { parentNumber: '2' }
                    )}
                  />
                )}
              </div>
            </Columns>

            {gametesOptions.length > 0 && (
              <>
                <h3 className='heading3'>
                  {t(
                    'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.heading'
                  )}
                </h3>
                <RegisteredRadioGroup
                  required
                  errorMessage={
                    errors.reproductiveMaterial?.donationMaterial?.message
                  }
                  name={'reproductiveMaterial.donationMaterial'}
                  register={register}
                  options={gametesOptions}
                  label={t(
                    'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.donation.subheading'
                  )}
                />
              </>
            )}

            <h3 className='heading3'>
              {t(
                'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.document.heading'
              )}
            </h3>
            <Columns numOfCols={2}>
              <RegisteredSelect
                name={'reproductiveMaterialDocType'}
                errorMessage={errors.reproductiveMaterialDocType?.message}
                register={register}
                label={t(
                  'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.document.select'
                )}
                options={centredeprocreationassistelettreSelection}
                placeholder={t('common.forms.selectPlaceholder')}
              />
              <RegisteredDateField
                name={'reproductiveMaterialDocExpiration'}
                errorMessage={errors.reproductiveMaterialDocExpiration?.message}
                register={register}
                label={t(
                  'parentAuth.changeForm.reproductiveMaterialUsed.reproductiveMaterial.document.date'
                )}
              />
            </Columns>

            <h2 className='heading2' id='surrogacyAgreement'>
              {t('parentAuth.changeForm.surrogacyAgreement.heading')}
            </h2>
            <h3 className='my-6 heading3'>
              {t('parentAuth.changeForm.surrogacyAgreement.subheading')}
            </h3>
            <Columns numOfCols={2}>
              <RegisteredDateField
                required
                name={'agreement.agreementDate'}
                errorMessage={errors.agreement?.agreementDate?.message}
                register={register}
                label={t('parentAuth.changeForm.surrogacyAgreement.date')}
              />
            </Columns>

            <h3 className='heading3'>
              {t('parentAuth.changeForm.surrogacyAgreement.draftingLanguage')}
            </h3>
            <RegisteredRadioGroup
              required
              name={'agreement.language.isInFrench'}
              errorMessage={errors.agreement?.language?.isInFrench?.message}
              register={register}
              options={[
                { value: 'yes', label: t('common.yes') },
                { value: 'no', label: t('common.no') },
              ]}
              label={t(
                'parentAuth.changeForm.surrogacyAgreement.languageRadio'
              )}
            />
            {watch().agreement?.language?.isInFrench === 'no' && (
              <RegisteredTextField
                required
                name='agreement.language.agreementLanguage'
                errorMessage={
                  errors.agreement?.language?.agreementLanguage?.message
                }
                register={register}
                label={t(
                  'parentAuth.changeForm.surrogacyAgreement.ifNotFrench'
                )}
              />
            )}

            <h3 className='heading3'>
              {t(
                'parentAuth.changeForm.surrogacyAgreement.reimbursement.heading'
              )}
            </h3>
            <RegisteredRadioGroup
              required
              name={'agreement.reimbursement.isReimbursed'}
              errorMessage={
                errors.agreement?.reimbursement?.isReimbursed?.message
              }
              register={register}
              options={[
                { value: 'yes', label: t('common.yes') },
                { value: 'no', label: t('common.no') },
              ]}
              label={t(
                'parentAuth.changeForm.surrogacyAgreement.reimbursement.radio'
              )}
            />
            {watch().agreement?.reimbursement.isReimbursed === 'no' && (
              <>
                <p>
                  {t(
                    'parentAuth.changeForm.surrogacyAgreement.reimbursement.paragraph'
                  )}
                </p>
                <h3 className='heading3'>
                  {t('parentAuth.changeForm.attachDoc1.heading')}
                </h3>
                <Columns numOfCols={2}>
                  <RegisteredDateField
                    required
                    name={'agreement.reimbursement.signDate'}
                    errorMessage={
                      errors.agreement?.reimbursement?.signDate?.message
                    }
                    register={register}
                    label={t('parentAuth.changeForm.attachDoc1.date')}
                  />
                </Columns>
              </>
            )}

            <h2 className='heading2' id='signedDeclaration'>
              {t('parentAuth.changeForm.attachDoc2.heading')}
            </h2>
            <h3 className='my-6 heading3'>
              {t('parentAuth.changeForm.attachDoc2.subheading')}
            </h3>
            <Columns numOfCols={2}>
              <RegisteredDateField
                required
                name={'agreement.authorizationSignDate'}
                errorMessage={errors.agreement?.authorizationSignDate?.message}
                register={register}
                label={t('parentAuth.changeForm.attachDoc2.date')}
              />
            </Columns>
            <h3 className='heading3 mt-20'>
              {t('changeRequest.signatureForm')}
            </h3>

            <Columns numOfCols={2}>
              <div className='flex flex-col'>
                <h4 className={'heading4 mb-2 space-x-4'}>
                  <span>
                    {selfParent?.firstName} {selfParent?.lastName}
                  </span>
                  {selfParentHasSigned ? (
                    <Pill
                      type={'success'}
                      text={t('common.signature.signedLabel')}
                    />
                  ) : (
                    <Pill
                      type={'warning'}
                      text={t('common.signature.toSignLabel')}
                    />
                  )}
                </h4>

                {!selfParentHasSigned ? (
                  <>
                    <p className={'mb-8'}>
                      {t('common.signature.clickToSign')}
                    </p>
                    <Button
                      onClick={sign}
                      primary
                      label={t('changeRequest.button')}
                      className='inline mr-auto'
                    />
                  </>
                ) : (
                  <>
                    <p className={'mb-8'}>
                      {t('common.signature.signed', {
                        name: `${selfParent?.firstName} ${selfParent?.lastName}`,
                        time: getFormattedDateTime(
                          selfParentHasSigned,
                          i18n.language
                        ),
                      })}
                    </p>
                  </>
                )}
              </div>
              {otherParent && (
                <>
                  <div className='flex flex-col'>
                    <h4 className={'heading4 mb-2 space-x-4'}>
                      <span>
                        {otherParent?.firstName} {otherParent?.lastName}
                      </span>
                      {otherParentHasSigned ? (
                        <Pill
                          type={'success'}
                          text={t('common.signature.signedLabel')}
                        />
                      ) : (
                        <Pill
                          type={'warning'}
                          text={t('common.signature.toSignLabel')}
                        />
                      )}
                    </h4>
                    {otherParentHasSigned ? (
                      <p className={'mb-8'}>
                        {t('common.signature.signed', {
                          name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                          time: getFormattedDateTime(
                            otherParentHasSigned,
                            i18n.language
                          ),
                        })}
                      </p>
                    ) : (
                      <p className={'mb-8'}>
                        {t('common.signature.mustSign', {
                          name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                        })}
                      </p>
                    )}
                  </div>
                </>
              )}
            </Columns>
          </SectionContainer>
        </div>
      </Form>
    </ScreenContainer>
  );
};

export default ParentAuth;
