import { useEffect, useState } from 'react';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import { useTranslation } from 'react-i18next';
import toKebabCase from '../utils/toKebabCase.utils';
import Stepper from '../components/Stepper';
import { steps } from '../utils/stepNavUtils';
import Columns from '../components/containers/Columns';
import Button, { ButtonTypes } from '../forms/Button';
import FormCompletion from '../components/FormCompletion';
import { formCompleteMockData } from '../mockData/formCompleteMockData';
import Files from '../components/Files';
import StepPagination from '../components/StepPagination';
import Form from '../forms/Form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import { useForm } from 'react-hook-form';
import RequestDetails from '../components/RequestDetails';
import Steps from '../components/stepContainers/Steps';
import { useApi } from '../api/APIContext';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import MessagesTable from '../components/MessagesTable';
import { navigateTo } from '../utils/navigation.utils';
import Spinner from '../components/Spinner';
import { downloadHTML, generatePDF } from '../utils/pageToPdf';
import { Margin, usePDF } from 'react-to-pdf';

const Dashboard = () => {
  const api = useApi();

  const [demande, setDemande] = useState<DemandeGPAClientPayload>();
  const { t } = useTranslation();
  const { handleSubmit, register, watch } = useForm();

  const [step, setStep] = useState(demande?.stage?.step || 0);

  const stepObjects: string[] = t('stepper.steps', {
    returnObjects: true,
  });
  const stepsData = steps(stepObjects, demande?.stage?.step ?? 0);
  const { toPDF, targetRef } = usePDF({
    method: 'build',
    filename: 'change_request_signature.pdf',
    page: { margin: Margin.MEDIUM },
  });
  const closeDemande = (data: any) => {};
  const refreshDemande = () => {
    api?.demandes
      .getActivedemande()
      .then((d) => {
        setDemande(d);
        if (!d) {
          return navigateTo('/portal');
        }
        setStep(d.stage.step);
      })
      .catch(() => {
        return navigateTo('/portal');
      });
  };

  useEffect(() => {
    refreshDemande();
  }, [api]);

  useEffect(() => {
    if (!api) return;
    if (demande?.stage?.step === 1 && step > 1) {
      api?.demandes?.pingDemande().then(refreshDemande);
    }
  }, [api, demande, step]);

  if (!demande) {
    return <Spinner />;
  }

  return (
    <ScreenContainer>
      <div ref={targetRef}>
        <SectionContainer>
          <h1 className='heading1'>{t('dashboard.title')}</h1>
          <h2 className='heading2'>{t('dashboard.requestDetails')}</h2>
          <RequestDetails requestData={demande} />

          <h2 id={toKebabCase(t('dashboard.progressId'))} className='heading2'>
            {t('dashboard.stepperTitle')}
          </h2>
          <Stepper
            steps={stepsData}
            step={demande.stage.step}
            reqStatus={
              demande.stage?.status === 'draft' ? 'draft' : 'confirmed'
            }
          />

          {step >= 2 && <MessagesTable demandeId={demande.id} />}
          <Steps step={step} />

          {step >= 2 && (
            <>
              <FormCompletion step={step} demande={demande.id} />
              <Files step={step} demandeId={demande.id} />
              <SectionContainer className='mb-28'>
                <h2 className='heading2'>
                  {t('dashboard.transmission.title')}
                </h2>
                <p>{t('dashboard.transmission.description')}</p>
                <div className='flex w-full flex-col items-center mt-8'>
                  <Button
                    onClick={() => {}}
                    primary
                    label={t('dashboard.transmission.button')}
                  />
                </div>
              </SectionContainer>
            </>
          )}

          <StepPagination
            step={step}
            setStep={setStep}
            steps={stepObjects}
            anchor='stepTitle'
          />

          {/*<Columns numOfCols={2}>*/}
          {/*  <div className='mt-16 mb-10'>*/}
          {/*    <h2 className='heading2'>{t('dashboard.closeRequest.title')}</h2>*/}
          {/*    <p>{t('dashboard.closeRequest.draftForm')}</p>*/}
          {/*    <Form onSubmit={handleSubmit(closeDemande)}>*/}
          {/*      <RegisteredCheckbox*/}
          {/*        name={'confirmClose'}*/}
          {/*        register={register}*/}
          {/*        label={t('dashboard.closeRequest.confirmClose')}*/}
          {/*      />*/}
          {/*      <Button*/}
          {/*        label={t('dashboard.closeRequest.closeRequestBtn')}*/}
          {/*        primary*/}
          {/*        type={ButtonTypes.submit}*/}
          {/*        disabled={!watch('confirmClose')}*/}
          {/*        className='shadow-none mt-12 px-12 bg-red-300 border-red-300 hover:bg-red-200 hover:border-red-200 disabled:bg-red-200 disabled:border-red-200'*/}
          {/*        compact*/}
          {/*      />*/}
          {/*    </Form>*/}
          {/*  </div>*/}
          {/*</Columns>*/}
        </SectionContainer>
      </div>
    </ScreenContainer>
  );
};

export default Dashboard;
