import ReactMarkdown from 'react-markdown';
import LinkList from '../LinkList';
import { useTranslation } from 'react-i18next';
import Accordion from '../containers/Accordion';

interface StepsProps {
  step: number;
}

interface Step {
  content: string;
  subContent?: string;
  accordions?: { title: string; content: string }[];
  afterContent?: string;
}

const Steps = ({ step }: StepsProps) => {
  const { t } = useTranslation();

  const step2Steps: Step[] = t('common.steps.step2.steps', {
    returnObjects: true,
  });

  const step3Steps: Step[] = t('common.steps.step3.steps', {
    returnObjects: true,
  });

  const step4Steps: Step[] = t('common.steps.step4.steps', {
    returnObjects: true,
  });

  const step5Steps: Step[] = t('common.steps.step5.steps', {
    returnObjects: true,
  });

  const step6Steps: Step[] = t('common.steps.step6.steps', {
    returnObjects: true,
  });

  const step7Steps: Step[] = t('common.steps.step7.steps', {
    returnObjects: true,
  });

  const step8Steps: Step[] = t('common.steps.step8.steps', {
    returnObjects: true,
  });

  switch (step) {
    case 1:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step1.title')}
          </h3>
          <ReactMarkdown className='whitespace-pre-wrap mb-10'>
            {t('common.steps.step1.description')}
          </ReactMarkdown>
          <LinkList
            title={t('common.steps.step1.documentsLabel1')}
            links={t('common.steps.step1.links1', { returnObjects: true })}
            headingLevel={'h4'}
          />
          <LinkList
            title={t('common.steps.step1.documentsLabel2')}
            links={t('common.steps.step1.links2', { returnObjects: true })}
            headingLevel={'h4'}
          />
        </>
      );
    case 2:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step2.title')}
          </h3>
          <ul className='pl-0 mb-8 list-none'>
            {step2Steps.map((step, index) => (
              <li className='px-2 py-10 border-b' key={index}>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {step.content}
                </ReactMarkdown>
                {step.subContent && (
                  <ReactMarkdown
                    className='p-5 pb-0 whitespace-pre-wrap'
                    components={{
                      a: ({ ...props }) => (
                        <a {...props} className='underline' target='_blank'>
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {step.subContent}
                  </ReactMarkdown>
                )}
                {step.accordions && (
                  <ul className='pl-0 mb-4 mt-7 list-none'>
                    {step.accordions.map((accordion, index) => (
                      <Accordion title={accordion.title} key={index}>
                        <ReactMarkdown>{accordion.content}</ReactMarkdown>
                      </Accordion>
                    ))}
                  </ul>
                )}
                {step.afterContent && (
                  <ReactMarkdown>{step.afterContent}</ReactMarkdown>
                )}
              </li>
            ))}
          </ul>
        </>
      );
    case 3:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step3.title')}
          </h3>
          <ul className='pl-0 mb-8 list-none'>
            {step3Steps.map((step, index) => (
              <li className='px-2 py-10 border-b' key={index}>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {step.content}
                </ReactMarkdown>
                {step.subContent && (
                  <ReactMarkdown
                    className='p-5 pb-0 whitespace-pre-wrap'
                    components={{
                      a: ({ ...props }) => (
                        <a {...props} className='underline' target='_blank'>
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {step.subContent}
                  </ReactMarkdown>
                )}
                {step.accordions && (
                  <ul className='pl-0 mb-8 mt-6 list-none'>
                    {step.accordions.map((accordion, index) => (
                      <Accordion title={accordion.title} key={index}>
                        <ReactMarkdown>{accordion.content}</ReactMarkdown>
                      </Accordion>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </>
      );
    case 4:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step4.title')}
          </h3>
          <ul className='pl-0 mb-8 list-none'>
            {step4Steps.map((step, index) => (
              <li className='px-2 py-10 border-b' key={index}>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {step.content}
                </ReactMarkdown>
                {step.subContent && (
                  <ReactMarkdown
                    className='p-5 pb-0 whitespace-pre-wrap'
                    components={{
                      a: ({ ...props }) => (
                        <a {...props} className='underline' target='_blank'>
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {step.subContent}
                  </ReactMarkdown>
                )}
                {step.accordions && (
                  <ul className='pl-0 mb-8 list-none'>
                    {step.accordions.map((accordion, index) => (
                      <Accordion title={accordion.title} key={index}>
                        <ReactMarkdown>{accordion.content}</ReactMarkdown>
                      </Accordion>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </>
      );
    case 5:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step5.title')}
          </h3>
          <ul className='pl-0 mb-8 list-none'>
            {step5Steps.map((step, index) => (
              <li className='px-2 py-10 border-b' key={index}>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {step.content}
                </ReactMarkdown>
                {step.subContent && (
                  <ReactMarkdown
                    className='p-5 pb-0 whitespace-pre-wrap'
                    components={{
                      a: ({ ...props }) => (
                        <a {...props} className='underline' target='_blank'>
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {step.subContent}
                  </ReactMarkdown>
                )}
                {step.accordions && (
                  <ul className='pl-0 mb-8 list-none'>
                    {step.accordions.map((accordion, index) => (
                      <Accordion title={accordion.title} key={index}>
                        <ReactMarkdown>{accordion.content}</ReactMarkdown>
                      </Accordion>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </>
      );
    case 6:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step6.title')}
          </h3>
          <ul className='pl-0 mb-8 list-none'>
            {step6Steps.map((step, index) => (
              <li className='px-2 py-10 border-b' key={index}>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {step.content}
                </ReactMarkdown>
                {step.subContent && (
                  <ReactMarkdown
                    className='p-5 pb-0 whitespace-pre-wrap'
                    components={{
                      a: ({ ...props }) => (
                        <a {...props} className='underline' target='_blank'>
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {step.subContent}
                  </ReactMarkdown>
                )}
                {step.accordions && (
                  <ul className='pl-0 mb-8 list-none'>
                    {step.accordions.map((accordion, index) => (
                      <Accordion title={accordion.title} key={index}>
                        <ReactMarkdown>{accordion.content}</ReactMarkdown>
                      </Accordion>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </>
      );
    case 7:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step7.title')}
          </h3>
          <ul className='pl-0 mb-8 list-none'>
            {step7Steps.map((step, index) => (
              <li className='px-2 py-10 border-b' key={index}>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {step.content}
                </ReactMarkdown>
                {step.subContent && (
                  <ReactMarkdown
                    className='p-5 pb-0 whitespace-pre-wrap'
                    components={{
                      a: ({ ...props }) => (
                        <a {...props} className='underline' target='_blank'>
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {step.subContent}
                  </ReactMarkdown>
                )}
                {step.accordions && (
                  <ul className='pl-0 mb-8 list-none'>
                    {step.accordions.map((accordion, index) => (
                      <Accordion title={accordion.title} key={index}>
                        <ReactMarkdown>{accordion.content}</ReactMarkdown>
                      </Accordion>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </>
      );
    case 8:
      return (
        <>
          <h3 className='heading3' id='stepTitle'>
            {t('common.steps.step8.title')}
          </h3>
          <ul className='pl-0 mb-8 list-none'>
            {step8Steps.map((step, index) => (
              <li className='px-2 py-10 border-b' key={index}>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {step.content}
                </ReactMarkdown>
                {step.subContent && (
                  <ReactMarkdown
                    className='p-5 pb-0 whitespace-pre-wrap'
                    components={{
                      a: ({ ...props }) => (
                        <a {...props} className='underline' target='_blank'>
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {step.subContent}
                  </ReactMarkdown>
                )}
                {step.accordions && (
                  <ul className='pl-0 mb-8 list-none'>
                    {step.accordions.map((accordion, index) => (
                      <Accordion title={accordion.title} key={index}>
                        <ReactMarkdown>{accordion.content}</ReactMarkdown>
                      </Accordion>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </>
      );
    default:
      return null;
  }
};

export default Steps;
