import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import FilePicker, { FilePickerProps } from '../FilePicker';

interface RegisteredFilePickerProps<
  RegisteredFilePickerType extends FieldValues,
> extends FilePickerProps {
  name: Path<RegisteredFilePickerType>;
  register: UseFormRegister<RegisteredFilePickerType>;
}

const RegisteredFilePicker = <RegisteredFilePickerType extends FieldValues>({
  register,
  ...props
}: RegisteredFilePickerProps<RegisteredFilePickerType>) => {
  const { ref, ...registerProps } = register(props.name);

  return (
    <FilePicker
      {...(props as FilePickerProps)}
      {...registerProps}
      filePickerRef={ref}
    />
  );
};

export default RegisteredFilePicker;
