const init = (
  oAuthUrl?: string,
  azFunctionsUrl?: string,
  appUrl?: string,
  oauthClientId?: string
) => {
  (window as any)._gpa_hq_config = {
    oAuthUrl: oAuthUrl || process.env.REACT_APP_GPA_HQ_OAUTH_URL || '',
    azFunctionsUrl:
      azFunctionsUrl || process.env.REACT_APP_GPA_HQ_AZ_FUNCTIONS || '',
    appUrl: appUrl || process.env.REACT_APP_GPA_HQ_APP_URL || '',
    oauthClientId:
      oauthClientId || process.env.REACT_APP_GPA_HQ_OAUTH_CLIENT_ID || '',
  };
};

const gpahqEnv = () => {
  return (window as any)._gpa_hq_config || {};
};
const getJwt = () => {
  //get _MSSSGPAHQ_JWT cookie
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('_MSSSGPAHQ_JWT=')) {
      return cookie.substring(15);
    }
  }
  return undefined;
};
const setJwt = (jwt: string) => {
  try {
    const jwtExp = JSON.parse(atob(jwt.split('.')[1])).exp;
    //set cookie to expire 5 minutes before jwt expiration
    const d = new Date();
    d.setTime(jwtExp * 1000 - 60000);
    document.cookie = `_MSSSGPAHQ_JWT=${jwt};expires=${d.toUTCString()};path=/`;
  } catch (e) {
    console.error('Invalid JWT');
    return;
  }
};

const catchJwtFromBody = ({ jwt }: { jwt: string }) => {
  if (jwt) {
    setJwt(jwt);
    return jwt;
  }
  return undefined;
};

export { init, gpahqEnv, getJwt, setJwt, catchJwtFromBody };
