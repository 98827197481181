import { getJwt, gpahqEnv } from '../environment';

export const dataverseFetch = <T>(
  path: string,
  options?: RequestInit
): Promise<T> => {
  if (!options) {
    options = {};
  }
  options.headers = {
    ...options.headers,
    Authorization: 'Bearer ' + getJwt(),
  };

  return fetch(
    `${gpahqEnv().azFunctionsUrl || process.env.REACT_APP_GPA_HQ_AZ_FUNCTIONS}${path}`,
    options
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};
