import { dataverseFetch } from './http';

export interface MessageCreation {
  subject: string;
  message: string;
  demandeId: string;
}

export interface MessageReplyDto {
  replyToMessageId: string;
  message: string;
}

export interface DemandeMessage {
  id: string;
  subject: string;
  message: string;
  sender: string;
  createdAt: string;
  demandeId: string;
  conversationId: string;
  fileNumber: string;
  inReplyToMessageId?: string;
}

export const getMessageById = async (messageId: string) =>
  dataverseFetch<DemandeMessage>(`/messages/${messageId}`, { method: 'GET' });
export const getMessages = async (demandeId: string) =>
  dataverseFetch<DemandeMessage[]>(`/demandes/${demandeId}/messages`, {
    method: 'GET',
  });

export const createMessage = async (data: MessageCreation) =>
  dataverseFetch<void>(`/demandes/${data.demandeId}/messages`, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const replyToMessage = async (data: MessageReplyDto) =>
  dataverseFetch<void>(`/messages/${data.replyToMessageId}/reply`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
