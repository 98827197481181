import { useTranslation } from 'react-i18next';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import ReactMarkdown from 'react-markdown';
import Columns from '../components/containers/Columns';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import { useForm } from 'react-hook-form';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import RegisteredTextArea from '../forms/registeredFields/RegisteredTextArea';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import { Margin, usePDF } from 'react-to-pdf';
import { useApi } from '../api/APIContext';
import React, { useEffect, useRef, useState } from 'react';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import { dataverseFetch } from '../api/methods/http';
import { getFormattedDateTime } from '../lib/dateformat';
import Pill from '../components/Pill';
import { downloadHTML } from '../utils/pageToPdf';
export interface ChangeRequestPayload {
  id?: string;
  changeSurrogate?: boolean;
  changeDesignatedState?: boolean;
  changeParentStatus?: boolean;
  changeAgreement?: boolean;
  other?: boolean;
  yourMessage?: string;
  specify?: string;
  signed_pi1?: string;
  signed_pi2?: string;
}

const ChangeRequest = () => {
  const { t, i18n } = useTranslation();
  const api = useApi();
  const [demande, setDemande] = useState<DemandeGPAClientPayload>();

  const [changeRequest, setChangeRequest] = useState<ChangeRequestPayload>();
  const { handleSubmit, register, watch, setValue } = useForm({
    defaultValues: changeRequest,
  });
  const targetRef = useRef(null);

  const selfParent = demande?.parents?.find((p) => p.isSelf);
  const otherParent =
    !!demande?.parents?.[1] && demande?.parents?.find((p) => !p.isSelf);

  const selfPINumber = (demande?.parents?.findIndex((p) => p.isSelf) ?? -1) + 1;
  const selfParentHasSigned =
    selfPINumber == 1 ? changeRequest?.signed_pi1 : changeRequest?.signed_pi2;
  const otherPINumber =
    (demande?.parents?.findIndex((p) => !p.isSelf) ?? -1) + 1;
  const otherParentHasSigned =
    otherPINumber > 0
      ? otherPINumber == 1
        ? changeRequest?.signed_pi1
        : changeRequest?.signed_pi2
      : undefined;

  const someoneHasSigned = !!selfParentHasSigned || !!otherParentHasSigned;

  const setCR = async (cr: ChangeRequestPayload) => {
    setChangeRequest(cr);
    //update form with new values
    Object.entries(cr).forEach(([key, value]) => {
      //@ts-ignore
      setValue(key, value);
    });
    if (
      (demande?.isCouple && cr.signed_pi1 && cr.signed_pi2) ||
      (!demande?.isCouple && cr.signed_pi1)
    ) {
      if (!demande) {
        return;
      }
      const html = await downloadHTML(t('changeRequest.title'));
      if (!html) return;
      await api.files.uploadFile(
        demande?.id,
        demande?.stage?.step,
        t('changeRequest.title') + '.html',
        html
      );
    }
  };

  useEffect(() => {
    api?.demandes
      .getActivedemande()
      .then((d) => {
        setDemande(d);
        if (!d) {
          return (window.location.href = '/portal');
        }
        dataverseFetch<ChangeRequestPayload>(
          `/demandes/${d.id}/changerequest`
        ).then(setCR);
      })
      .catch(() => {
        return (window.location.href = '/portal');
      });
  }, [api]);
  const submitFn = (data: any) => {
    dataverseFetch<ChangeRequestPayload>(
      `/demandes/${demande?.id}/changerequest`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      }
    ).then(setCR);
    // toPDF();
  };
  if (!demande) return <></>;
  return (
    <ScreenContainer>
      <SectionContainer className={'exportable-form'}>
        <h1 className='heading1'>{t('changeRequest.title')}</h1>
        {/*<RequestDetails requestData={requestData} />*/}

        <Form onSubmit={handleSubmit(submitFn)}>
          <h2 className='heading2 md:w-9/12'>
            {t('changeRequest.changeAgreement')}
          </h2>
          <p>
            {t(
              `changeRequest.${!demande.parents?.[1] ? 'oneIdentifier' : 'twoIdentifiers'}.informDGPAHQ`
            )}
          </p>

          <SectionContainer className='pl-6'>
            <RegisteredCheckbox
              name={'changeSurrogate'}
              register={register}
              disabled={someoneHasSigned}
              label={t('changeRequest.form.changeSurrogate')}
            />
            <RegisteredCheckbox
              name={'changeDesignatedState'}
              register={register}
              disabled={someoneHasSigned}
              label={t('changeRequest.form.changeDesignatedState')}
            />
            <RegisteredCheckbox
              name={'changeParentStatus'}
              register={register}
              disabled={someoneHasSigned}
              label={t('changeRequest.form.changeParentStatus')}
            />
            <RegisteredCheckbox
              name={'changeAgreement'}
              register={register}
              disabled={someoneHasSigned}
              label={t('changeRequest.form.changeAgreement')}
            />
            <RegisteredCheckbox
              name={'other'}
              register={register}
              disabled={someoneHasSigned}
              label={t('changeRequest.form.other')}
            />
            {watch('other') && (
              <Columns numOfCols={2}>
                <RegisteredTextField
                  name={'specify'}
                  register={register}
                  disabled={someoneHasSigned}
                  label={t('changeRequest.form.specify')}
                  required={watch('other')}
                />
              </Columns>
            )}
          </SectionContainer>
          <RegisteredTextArea
            name={'yourMessage'}
            register={register}
            disabled={someoneHasSigned}
            title={t('changeRequest.form.explainClearReq')}
            rows={4}
          />

          <ReactMarkdown className='whitespace-pre-wrap my-6'>
            {t(
              `changeRequest.${!demande.parents?.[1] ? 'oneIdentifier' : 'twoIdentifiers'}.notesOnDGPAHQ`
            )}
          </ReactMarkdown>
          <h3 className='heading3 mt-20'>{t('changeRequest.signatureForm')}</h3>

          <Columns numOfCols={2}>
            <div className='flex flex-col'>
              <h4 className={'heading4 mb-2 space-x-4'}>
                <span>
                  {selfParent?.firstName} {selfParent?.lastName}
                </span>
                {selfParentHasSigned ? (
                  <Pill
                    type={'success'}
                    text={t('common.signature.signedLabel')}
                  />
                ) : (
                  <Pill
                    type={'warning'}
                    text={t('common.signature.toSignLabel')}
                  />
                )}
              </h4>

              {!selfParentHasSigned ? (
                <>
                  <p className={'mb-8'}>{t('common.signature.clickToSign')}</p>
                  <Button
                    onClick={() => {}}
                    primary
                    type={ButtonTypes.submit}
                    label={t('changeRequest.button')}
                    className='inline mr-auto'
                  />
                </>
              ) : (
                <>
                  <p className={'mb-8'}>
                    {t('common.signature.signed', {
                      name: `${selfParent?.firstName} ${selfParent?.lastName}`,
                      time: getFormattedDateTime(
                        selfParentHasSigned,
                        i18n.language
                      ),
                    })}
                  </p>
                </>
              )}
            </div>
            {otherParent && (
              <>
                <div className='flex flex-col'>
                  <h4 className={'heading4 mb-2 space-x-4'}>
                    <span>
                      {otherParent?.firstName} {otherParent?.lastName}
                    </span>
                    {otherParentHasSigned ? (
                      <Pill
                        type={'success'}
                        text={t('common.signature.signedLabel')}
                      />
                    ) : (
                      <Pill
                        type={'warning'}
                        text={t('common.signature.toSignLabel')}
                      />
                    )}
                  </h4>
                  {otherParentHasSigned ? (
                    <p className={'mb-8'}>
                      {t('common.signature.signed', {
                        name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                        time: getFormattedDateTime(
                          otherParentHasSigned,
                          i18n.language
                        ),
                      })}
                    </p>
                  ) : (
                    <p className={'mb-8'}>
                      {t('common.signature.mustSign', {
                        name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                      })}
                    </p>
                  )}
                </div>
              </>
            )}
          </Columns>
        </Form>
      </SectionContainer>
    </ScreenContainer>
  );
};

export default ChangeRequest;
