import React, { createContext, PropsWithChildren, useContext } from 'react';
import * as Api from './api'; // Import your API functions

const ApiContext = createContext<typeof Api>(Api);

interface ApiContextProps {
  oAuthUrl?: string;
  azFunctionsUrl?: string;
  appUrl?: string;
  oauthClientId?: string;
}

export const ApiProvider = (props: PropsWithChildren<ApiContextProps>) => {
  const { oAuthUrl, azFunctionsUrl, appUrl, oauthClientId } = props;
  Api.init(oAuthUrl, azFunctionsUrl, appUrl, oauthClientId);

  return (
    <ApiContext.Provider value={Api}>{props.children}</ApiContext.Provider>
  );
};

// Custom hook to use the API
export const useApi = () => useContext(ApiContext);
