import { LegacyRef } from 'react';
import classNames from '../utils/classList.utils';
import { Size, sizeToWidth } from '../utils/fieldSize.utils';

export interface SelectOption {
  readonly label: string | number;
  readonly value: string | number;
}

export interface SelectProps {
  disabled?: boolean;
  errorMessage?: string;
  label: string;
  name: string;
  options: SelectOption[];
  placeholder: string;
  required?: boolean;
  selectRef?: LegacyRef<HTMLSelectElement>;
  size?: Size;
  defaultValue?: string | number;
}

const Select = ({
  disabled,
  errorMessage,
  label,
  options,
  placeholder,
  required,
  size = 'full',
  selectRef,
  defaultValue,
  ...props
}: SelectProps) => {
  const { name } = props;

  return (
    <div className='mb-8'>
      <label htmlFor={name} className={classNames('block font-bold mb-2')}>
        <span>
          {label}
          {required && <span className='font-bold text-red-300'>&nbsp;*</span>}
        </span>
      </label>
      <select
        disabled={disabled}
        id={name}
        {...props}
        ref={selectRef}
        defaultValue={defaultValue || ''}
        className={classNames(
          sizeToWidth(size),
          'bg-white relative h-10 flex w-full border pl-2 pr-10 py-2 text-left paragraph',
          errorMessage
            ? 'border-red-300 border-2'
            : 'border-gray-300 focus:ring-2 focus:ring-blue-300'
        )}
      >
        {placeholder && (
          <option value='' disabled hidden className='text-gray-300'>
            {placeholder}
          </option>
        )}
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      {errorMessage && (
        <p className='mt-2 text-sm font-bold text-red-300'>{errorMessage}</p>
      )}
    </div>
  );
};

export default Select;
