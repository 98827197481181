import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import Checkbox, { CheckboxProps } from '../Checkbox';

interface RegisteredCheckboxProps<RegisteredCheckboxType extends FieldValues>
  extends CheckboxProps {
  name: Path<RegisteredCheckboxType>;
  register: UseFormRegister<RegisteredCheckboxType>;
}

const RegisteredCheckbox = <RegisteredCheckboxType extends FieldValues>({
  register,
  ...props
}: RegisteredCheckboxProps<RegisteredCheckboxType>) => {
  const { ref, ...registerProps } = register(props.name);

  return (
    <Checkbox
      {...(props as CheckboxProps)}
      {...registerProps}
      checkboxRef={ref}
    />
  );
};

export default RegisteredCheckbox;
