import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import AddParentCard from '../components/personalInfo/AddParentCard';
import ParentForm from '../components/personalInfo/ParentForm';
import Button from '../forms/Button';
import { useEffect, useRef, useState } from 'react';
import { ParentPayload } from '../api/methods/demandes';
import { useApi } from '../api/APIContext';
import BackButton from '../components/BackButton';

const PersonalInfo = () => {
  const [parents, setParents] = useState<ParentPayload[]>([]);
  const [linkingInfo, setLinkingInfo] = useState<{
    code: string;
    isUnique: boolean;
    canLink: boolean;
  }>();
  const { t } = useTranslation();
  const api = useApi();
  const formRef1 = useRef<HTMLFormElement>(null);
  const formRef2 = useRef<HTMLFormElement>(null);

  useEffect(() => {
    api.demandes.getParents().then((p) => {
      setParents(p);
      if (!p[1]) {
        api.demandes.getLinkingInformation().then(setLinkingInfo);
      }
    });
  }, [api.demandes]);

  return (
    <ScreenContainer>
      <BackButton />

      <SectionContainer>
        <h1 className='heading1'>{t('personalInfo.heading')}</h1>
        <Columns numOfCols={2} className='md:gap-24'>
          <div>
            <h3 className='mt-8 heading3'>
              {parents[1]
                ? t('personalInfo.parentForm.heading', { number: '1' })
                : t('personalInfo.parentForm.heading', { number: '' })}
            </h3>
            {parents[0] && (
              <ParentForm parent={parents[0]} formRef={formRef1} />
            )}
          </div>
          <div>
            <h3 className='mt-8 heading3'>
              {t('personalInfo.parentForm.heading', { number: '2' })}
            </h3>
            {parents[1] ? (
              <ParentForm parent={parents[1]} formRef={formRef2} />
            ) : linkingInfo ? (
              <AddParentCard
                intendedParent={parents[0]?.lastName}
                code={linkingInfo?.code}
                isUnique={linkingInfo?.isUnique}
                canLink={linkingInfo?.canLink}
              />
            ) : (
              <></>
            )}
          </div>
        </Columns>
        <Button
          onClick={() => {
            formRef1.current?.requestSubmit();
            formRef2.current?.requestSubmit();
          }}
          label={t('common.forms.save')}
          primary
        />
      </SectionContainer>
      {parents[0] && parents[1] && (
        <SectionContainer className='mt-20'>
          <h2 className='heading2'>
            {t('personalInfo.parentForm.twoParentNote.heading')}
          </h2>
          <ReactMarkdown
            className='mb-5 whitespace-pre-wrap'
            components={{
              a: ({ ...props }) => (
                <a {...props} className='underline'>
                  {props.children}
                </a>
              ),
            }}
          >
            {t('personalInfo.parentForm.twoParentNote.paragraph')}
          </ReactMarkdown>
        </SectionContainer>
      )}
    </ScreenContainer>
  );
};

export default PersonalInfo;
