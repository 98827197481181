import classNames from '../../../utils/classList.utils';
import { ReactElement } from 'react';

interface TableRowProps {
  elements: ReactElement[];
  className: string;
}

const TableRows = ({ elements, className }: TableRowProps) => {
  return (
    <div className={classNames('border-b-2', className)}>
      {elements.map((element, i) => (
        <div key={`${i}`} className='px-4 py-2 min-w-20'>
          {element}
        </div>
      ))}
    </div>
  );
};

export default TableRows;
