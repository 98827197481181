import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Stepper from '../components/Stepper';
import Steps from '../components/stepContainers/Steps';
import StepPagination from '../components/StepPagination';
import Columns from '../components/containers/Columns';
import Button from '../forms/Button';
import Spinner from '../components/Spinner';
import { useEffect } from 'react';
import { useApi } from '../api/APIContext';

const WaitForDemande = () => {
  const api = useApi();
  useEffect(() => {
    const interval = setInterval(() => {
      api.demandes
        .getActivedemande()
        .then((demande) => {
          if (demande) {
            window.location.href = `/dashboard?demandeId=${demande.id}`;
          }
        })
        .catch(() => {});
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>
          Création de votre demande <Spinner className={'w-6 h-6'} />
        </h1>
        <p>
          Veuillez patienter, la création de votre demande est en cours. Vous
          serez automatiquement redirigé vers celle-ci lorsqu'elle sera prête.
        </p>
      </SectionContainer>
      <SectionContainer>
        <Button label={'Voir ma liste de demandes'} href={'/portal'} />
      </SectionContainer>
    </ScreenContainer>
  );
};

export default WaitForDemande;
