export interface Parent {
  firstName: string;
  lastName: string;
  usualName: string;
  address: string;
  city: string;
  postalCode: string;
  dob: string;
  email?: string;
  telephone?: string;
}

export interface RequestData {
  parent1: Parent;
  parent2?: Parent;
  fileType: 'couple' | 'single';
  requestNumber: number;
  createdAt: Date;
  status: 'draft' | 'confirmed';
  currentStep: number;
  inCanada: boolean;
}

export const mockParent1: Parent = {
  firstName: 'Jean',
  lastName: 'Untel',
  usualName: '',
  address: '',
  city: '',
  postalCode: '',
  dob: new Date(1980, 0, 1).toDateString(),
  email: 'jean@untel.ca',
  telephone: '514 555-1234',
};

export const mockParent2: Parent = {
  firstName: 'Dominique',
  lastName: 'Beaumier',
  usualName: '',
  address: '',
  city: '',
  postalCode: '',
  dob: new Date(1980, 0, 1).toDateString(),
  email: 'dominique@beaumier.ca',
  telephone: '514 555-1234',
};

export const mockRequestData: RequestData = {
  parent1: mockParent1,
  parent2: mockParent2,
  fileType: 'couple',
  requestNumber: 123456789,
  createdAt: new Date(),
  status: 'confirmed',
  inCanada: true,
  currentStep: 1,
};

export const mockDraftRequestData: RequestData = {
  parent1: mockParent1,
  fileType: 'single',
  requestNumber: 123456789,
  createdAt: new Date(),
  status: 'draft',
  currentStep: 1,
  inCanada: false,
};
