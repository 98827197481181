import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import DateField, { DateFieldProps } from '../DateField';

interface RegisteredDateFieldProps<RegisteredDateFieldType extends FieldValues>
  extends DateFieldProps {
  name: Path<RegisteredDateFieldType>;
  register: UseFormRegister<RegisteredDateFieldType>;
}

const RegisteredDateField = <RegisteredDateFieldType extends FieldValues>({
  register,
  ...props
}: RegisteredDateFieldProps<RegisteredDateFieldType>) => {
  const { ref, ...registerProps } = register(props.name);

  return (
    <DateField
      {...(props as DateFieldProps)}
      {...registerProps}
      datePickerRef={ref}
    />
  );
};

export default RegisteredDateField;
