import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import TextArea, { TextAreaProps } from '../TextArea';

interface RegisteredTextAreaProps<RegisteredTextAreaType extends FieldValues>
  extends TextAreaProps {
  name: Path<RegisteredTextAreaType>;
  register: UseFormRegister<RegisteredTextAreaType>;
}

const RegisteredTextArea = <RegisteredTextAreaType extends FieldValues>({
  register,
  ...props
}: RegisteredTextAreaProps<RegisteredTextAreaType>) => {
  const { ref, ...registerProps } = register(props.name);
  return (
    <TextArea
      {...(props as TextAreaProps)}
      {...registerProps}
      textAreaRef={ref}
    />
  );
};

export default RegisteredTextArea;
