import { LegacyRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useApi } from '../../api/APIContext';
import { ParentPayload } from '../../api/methods/demandes';
import Form from '../../forms/Form';
import RegisteredTextField from '../../forms/registeredFields/RegisteredTextField';
import { personalInfoDefaultValues } from '../../utils/personalInfo.util';
import Columns from '../containers/Columns';

interface ParentFormProps {
  parent: ParentPayload;
  formRef: LegacyRef<HTMLFormElement>;
}

const ParentForm = ({ parent, formRef }: ParentFormProps) => {
  const { t } = useTranslation();
  const api = useApi();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: personalInfoDefaultValues(parent),
    // resolver: zodResolver(personalInfoSchema(i18n.language)),
  });

  const submitFn = (data: any) => {
    api.demandes.saveParent(parent.id, data).then();
  };

  return (
    <Form onSubmit={handleSubmit(submitFn)} formRef={formRef}>
      <RegisteredTextField
        name={'lastName'}
        label={t('personalInfo.parentForm.familyName')}
        register={register}
        readonly
      />
      <RegisteredTextField
        name={'firstName'}
        label={t('personalInfo.parentForm.firstName')}
        register={register}
        readonly
      />
      <RegisteredTextField
        name={'address'}
        label={t('personalInfo.parentForm.address')}
        register={register}
        errorMessage={errors.address?.message}
      />
      <Columns numOfCols={2}>
        <RegisteredTextField
          name={'city'}
          label={t('personalInfo.parentForm.city')}
          register={register}
          errorMessage={errors.city?.message}
        />
        <RegisteredTextField
          name={'postalCode'}
          label={t('personalInfo.parentForm.postalCode')}
          register={register}
          errorMessage={errors.postalCode?.message}
        />
      </Columns>
      <RegisteredTextField
        name={'dob'}
        label={t('personalInfo.parentForm.birthday')}
        register={register}
        readonly
      />
      <RegisteredTextField
        name={'telephone'}
        label={t('personalInfo.parentForm.telephone')}
        register={register}
        errorMessage={errors.telephone?.message}
      />
      <ReactMarkdown
        className='mb-8'
        components={{
          p: ({ ...props }) => (
            <p {...props} className='text-sm'>
              {props.children}
            </p>
          ),
          a: ({ ...props }) => (
            <a {...props} className='text-sm underline' target='_blank'>
              {props.children}
            </a>
          ),
        }}
      >
        {t('personalInfo.parentForm.emailNote')}
      </ReactMarkdown>
    </Form>
  );
};

export default ParentForm;
