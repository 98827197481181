import { z } from 'zod';
import { translateLanguage } from './translateLanguage.utils';

export const requestAccessFormDefaultValues = {
  code: '',
  lastName: '',
};

export const generateRequestAccessFormSchema = (locale: string = 'fr') =>
  z.object({
    code: z.string().min(1, translateLanguage('common.forms.required', locale)),
    lastName: z
      .string()
      .min(1, translateLanguage('common.forms.required', locale)),
  });

export const RequestAccessSchemaVal = generateRequestAccessFormSchema();
export type RequestAccessSchema = z.infer<typeof RequestAccessSchemaVal>;
