import defaultTheme from 'tailwindcss/defaultTheme';

const theme = {
  colors: {
    blue: {
      100: '#DAE6F0',
      200: '#4A98D9',
      300: '#1472BF',
      400: '#095797', // secondary
      500: '#19406C',
      600: '#223654', // primary
    },
    gray: {
      100: '#F1F1F2',
      200: '#C5CAD2',
      300: '#8893A2',
      400: '#6B778A', // tertiary
      500: '#4E5662',
    },
    red: {
      100: '#FFDBD6',
      200: '#E58271', // accent
      300: '#CB381F',
      400: '#692519',
    },
    green: {
      100: '#D7F0BB',
      200: '#4F813D',
      300: '#2C4024',
    },
    yellow: {
      100: '#F8E69A',
      200: '#E0AD03',
      300: '#AD781C',
    },
    violet: '#6B4FA1',
  },
  fontSize: {
    base: ['16px', '24px'],
    lg: ['19px', '24px'],
    xl: ['21px', '24px'],
    '2xl': ['25px', '32px'],
    '3xl': ['28px', '32px'],
    '4xl': ['36px', '40px'],
    '5xl': ['48px', '56px'],
  },
  fontFamily: {
    roboto: ['Roboto', ...defaultTheme.fontFamily.sans],
    openSans: ['Open Sans', ...defaultTheme.fontFamily.sans],
  },
};

export default theme;
