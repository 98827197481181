export type Size = 'sm' | 'md' | 'lg' | 'xl' | 'full';

export const sizeToWidth = (size: Size) => {
  switch (size) {
    case 'sm':
      return 'md:w-16';
    case 'md':
      return 'md:w-38';
    case 'lg':
      return 'md:w-60';
    case 'xl':
      return 'md:w-132';
    case 'full':
      return 'md:w-full';
  }
};
